import React from 'react';
import {BREAKPOINT} from '../../lib/styles';

const HiddenOnDesktop = (props) => {
    const {children} = props;
    const {display} = props.style || {};

    const displayValue = display || props.display || 'block';

    return (
        <div {...props}>
            {children}
            <style jsx>
                {`
                    div {
                        display: ${displayValue};
                    }

                    @media screen and (min-width: ${BREAKPOINT}) {
                        div {
                            display: none;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default HiddenOnDesktop;
