import AktifyForm from 'aac-components/components/AktifyForm';
import IconCheckCircle from 'aac-components/components/Icons/Basic/IconCheckCircle';
import VobApp from 'aac-components/components/VobApp';
import {useState} from 'react';

import SlideUpStickyFooter from '../SlideUpStickyFooter';

// note: meant to be renderd on mobile only
const AktifyStickyFooter = (props) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const title =
        props?.title ||
        'Prefer texting to talking on the phone? We’ve got you covered 24/7.';

    const formHeaderCopy =
        props?.formHeaderCopy || 'Instead, sign up for text support to receive:';

    return (
        <div>
            <SlideUpStickyFooter title={title}>
                {!isSubmitted && (
                    <div className="form-header">
                        <div className="form-header__title">{formHeaderCopy}</div>
                        <ul>
                            <li>
                                <IconCheckCircle
                                    width="20px"
                                    height="20px"
                                    fill="var(--success)"
                                />
                                <span>
                                    {props.showVOB
                                        ? 'You will receive results in minutes.'
                                        : 'Resources about addiction and recovery'}
                                </span>
                            </li>
                            <li>
                                <IconCheckCircle
                                    width="20px"
                                    height="20px"
                                    fill="var(--success)"
                                />

                                <span>
                                    {props.showVOB
                                        ? 'There is no obligation to enter treatment.'
                                        : 'Information about our treatment process'}
                                </span>
                            </li>
                            {props.showVOB && (
                                <li>
                                    <IconCheckCircle
                                        width="20px"
                                        height="20px"
                                        fill="var(--success)"
                                    />

                                    <span>
                                        Your information is protected under HIPPA.
                                    </span>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
                {!props.showVOB && (
                    <AktifyForm
                        onSubmit={() => setIsSubmitted(true)}
                        automationTestingSelector="aktify-sticky-footer"
                    />
                )}
                {props.showVOB && <VobApp type={'one-step'} />}
            </SlideUpStickyFooter>
            <style jsx>
                {`
                    .form-header__title {
                        font-weight: bold;
                        text-align: center;
                    }
                    .form-header ul {
                        list-style: none;
                        padding: 0;
                        max-width: fit-content;
                        margin: 15px auto;
                    }
                    .form-header li {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        height: 30px;
                        margin-bottom: 0 auto 15px auto;
                        font-size: 14px;
                    }
                    .form-header li > span {
                        margin-left: 10px;
                    }
                `}
            </style>
        </div>
    );
};
export default AktifyStickyFooter;
