import React, {useContext, useState, useRef, useEffect} from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import IconMessageTyping from 'aac-components/components/Icons/Communication/IconMessageTyping';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';
import IconPhone from 'aac-components/components/Icons/Basic/IconPhone';
import IconInNetwork from 'aac-components/components/Icons/Misc/IconInNetwork';

import {BREAKPOINT} from '../../../lib/styles';
import {HEADER_NEXT_STEPS_HEIGHT} from '../../Header/index';
import {AppContext} from '../../../pages/_app';
import Helpline from '../../Helpline';

//time out delay 5000 ms (same as ab tasty test)
const MAX_DELAY = 5000;

const NextStepsLink = ({item, index, currentLinkNumber, isMobile}) => {
    const {
        icon = 'phone',
        link: {url = '', title = ''},
    } = item;
    const icons = {
        messageTyping: IconMessageTyping,
        phone: IconPhone,
        inNetwork: IconInNetwork,
    };
    const Icon = icons[icon] || IconPhone;
    const hasPhone = title.includes('[phone]');
    const hasInNetwork = title.includes('Treatment');
    const {callTrackingNumber} = useContext(AppContext);

    return (
        <>
            <div
                className={`nav-carousel__link-container ${
                    currentLinkNumber === index ? 'current' : 'hide'
                }`}>
                <Link
                    href={hasPhone ? callTrackingNumber?.href : url}
                    key={index}
                    className="nav-carousel__link">
                    {isMobile && hasInNetwork && (
                        <div className="nav-carousel__icons">
                            <Icon fill="var(--white)" />
                        </div>
                    )}
                    {!isMobile && (
                        <div className="nav-carousel__icons">
                            <Icon fill="var(--white)" />
                        </div>
                    )}
                    <div className="link-title">
                        {hasPhone ? (
                            <>
                                {title.replace('[phone]', '')}{' '}
                                <strong>{callTrackingNumber?.display}</strong>
                            </>
                        ) : (
                            title
                        )}
                    </div>
                </Link>

                {hasPhone && <Helpline theme="light" />}
            </div>
            <style jsx>{`
                .nav-carousel__link-container {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .nav-carousel__link-container {
                        background-color: transparent;
                        width: auto;
                        padding: 0;
                    }
                }
                :global(.nav-carousel__icons) {
                    margin-right: 10px;
                    display: flex;
                }

                :global(.nav-carousel__link) {
                    font-size: 11px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }
                :global(.nav-carousel__link .link-title) {
                    color: #fff;
                    font-weight: bold;
                    text-decoration: underline;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    :global(.nav-carousel__link) {
                        padding: 0;
                        width: auto;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .current {
                    display: flex;
                }
                .hide {
                    display: none;
                }
                @media screen and (max-width: ${BREAKPOINT}) {
                    :global(.nav-carousel__link .link-title) {
                        font-size: var(--p-size-xs);
                    }
                }
            `}</style>
        </>
    );
};

const NextStepsCarousel = ({disabled = false}) => {
    const [currentLinkNumber, setCurrentLinkNumber] = useState(0);
    const timeoutRef = useRef(null);

    const {isMobile} = useContext(AppContext);

    const router = useRouter();
    const {asPath} = router;

    const blacklist = [
        '/verify-insurance',
        '/aac-facilities',
        '/providers',
        '/aac/admissions/team/text-support',
    ];
    const isBlacklisted = blacklist.find((path) => asPath?.includes(path));
    //if disabled is true of a blacklisted url is present do not show
    if (disabled || isBlacklisted) return null;

    //array of links for desktop
    const desktopLinks = [
        {
            icon: 'inNetwork',
            link: {
                title: 'Check Your Treatment Coverage Online',
                url: '/verify-insurance/',
            },
        },
        {
            icon: 'messageTyping',
            link: {
                title: 'Get 24/7 Text Support For Addiction Questions',
                url: '/admissions/team/text-support/',
            },
        },
        {
            icon: 'phone',
            link: {
                title: 'Get Help Today [phone]',
            },
        },
    ];

    //timeout reset
    const handleResetTimeout = () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };

    // auto slide
    useEffect(() => {
        if (desktopLinks?.length > 1) {
            timeoutRef.current = setTimeout(
                () =>
                    setCurrentLinkNumber((prevLink) =>
                        prevLink === desktopLinks?.length - 1 ? 0 : prevLink + 1,
                    ),
                MAX_DELAY,
            );

            return () => {
                handleResetTimeout();
            };
        }
    }, [currentLinkNumber]);

    //cycle left arrow
    const handlePreviousImage = () => {
        if (currentLinkNumber <= 0) setCurrentLinkNumber(desktopLinks?.length - 1);
        else setCurrentLinkNumber(currentLinkNumber - 1);
    };
    //cycle right arrow
    const handleNextImage = () => {
        if (currentLinkNumber < desktopLinks?.length - 1)
            setCurrentLinkNumber(currentLinkNumber + 1);
        else setCurrentLinkNumber(0);
    };

    return (
        <>
            <div className="nav-carousel__container">
                <div className="nav-carousel__arrow-container">
                    <div className="nav-carousel__arrow-wrapper nav-carousel__arrow-left">
                        <div
                            className="nav-carousel__arrow"
                            onClick={handlePreviousImage}>
                            <IconChevron
                                rotate="90"
                                fill="var(--white)"
                                onClick={handlePreviousImage}
                            />
                        </div>
                    </div>
                </div>

                <div className="nav-carousel__slide-container">
                    {desktopLinks.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <NextStepsLink
                                    item={item}
                                    index={index}
                                    currentLinkNumber={currentLinkNumber}
                                    isMobile={isMobile}
                                />
                            </React.Fragment>
                        );
                    })}
                </div>

                <div className="nav-carousel__arrow-container">
                    <div className="nav-carousel__arrow-wrapper nav-carousel__arrow-right">
                        <div className="nav-carousel__arrow" onClick={handleNextImage}>
                            <IconChevron fill="var(--white)" rotate="-90" />
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .nav-carousel__container {
                    background: var(--secondary-400);
                    width: 100%;
                    height: 80px;
                    max-height: ${HEADER_NEXT_STEPS_HEIGHT}px;
                    opacity: 1;
                    visibility: visible;
                    position: relative;
                    box-shadow: none;
                    display: flex;
                    margin: auto;
                    align-items: center;
                    justify-content: center;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .nav-carousel__container {
                        width: 100%;
                        height: auto;
                        max-height: ${HEADER_NEXT_STEPS_HEIGHT}px;
                        opacity: 1;
                        visibility: visible;
                        position: relative;
                        box-shadow: none;
                        display: flex;
                        margin: auto;
                    }
                }

                .nav-carousel__arrow-container {
                    color: #fff;
                    width: 100%;
                    position: relative;
                    flex: 0 0 6%;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .nav-carousel__arrow-container {
                        flex: 0 0 10%;
                    }
                }
                .nav-carousel__arrow-wrapper {
                    display: flex;
                    height: 100%;
                    align-items: center;
                }
                .nav-carousel__arrow-right {
                    justify-content: flex-end;
                }

                .nav-carousel__arrow-left {
                    justify-content: flex-start;
                }
                .nav-carousel__arrow {
                    height: 24px;
                    width: fit-content;
                    cursor: pointer;
                }
                .nav-carousel__slide-container {
                    color: #fff;
                    width: 100%;
                    position: relative;
                    flex: 0 0 88%;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .nav-carousel__slide-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 12px;
                        height: ${HEADER_NEXT_STEPS_HEIGHT}px;
                        flex: 0 0 80%;
                    }
                }
                @media screen and (max-width: ${BREAKPOINT}) {
                    .nav-carousel__container {
                        height: 56px;
                    }
                }
            `}</style>
        </>
    );
};

export default NextStepsCarousel;
