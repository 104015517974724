import React from 'react';
import Link from 'next/link';

import MaxWidth from '../../MaxWidth';
import Image from '../../Image';
import SearchIcon from '../../Svgs/SearchIcon';

import {BREAKPOINT} from '../../../lib/styles';
import {extractSlug, getPathname} from '../../../lib/utils';
import {domainUrl} from '../../../lib/globals';

import SubNavStepCTADesktop from './SubNavStepCTA/SubNavStepCTADesktop';
import {zIndex} from 'aac-components/utils/helpers';

export default function TopNav({toggleSubNav, handleRef, activeSubName, menu}) {
    let timer;
    const enter = (name) => {
        timer = setTimeout(() => {
            toggleSubNav(name);
        }, 250);
    };
    const leave = () => {
        if (timer) {
            clearTimeout(timer);
        }
    };
    const activateSearch = (name) => {
        if (name === 'search' && typeof document !== 'undefined') {
            const searchInput = document.getElementById('site-search-input');
            searchInput && searchInput.focus();
        }
    };

    return (
        <nav className="top-nav" ref={handleRef}>
            <MaxWidth pageContentStyle={{margin: '0 auto'}}>
                <div className="top-nav-container">
                    <div className="logo-container" onMouseEnter={() => toggleSubNav('')}>
                        <Link href="/" title="Withdrawal.net">
                            <Image src="/static/wd-sub-brand-lockup-color.svg" />
                        </Link>
                    </div>
                    <ul className="nav-menu-container">
                        {menu &&
                            menu.map((section) => {
                                const {
                                    title: displayName = '',
                                    child_items: links = [],
                                    url,
                                } = section;

                                let name =
                                    displayName &&
                                    displayName.replace(/\ /g, '_').toLowerCase();

                                name = name.replace(/&/g, 'and');

                                const isActive = name === activeSubName ? 'active' : '';
                                const sectionTitleSlug = extractSlug(url);

                                return (
                                    <li
                                        key={name}
                                        className={`links__item links__item--${name}`}
                                        onClick={() => {
                                            toggleSubNav(name), activateSearch(name);
                                        }}
                                        onMouseEnter={() => enter(name)}
                                        onMouseLeave={() => leave()}>
                                        {links && (
                                            <div className="link">
                                                <div className={`link__text ${isActive}`}>
                                                    <Link href={sectionTitleSlug}>
                                                        {displayName}
                                                    </Link>
                                                </div>

                                                <div className="sub-nav-seo">
                                                    <ul>
                                                        {links &&
                                                            links.map((section) => {
                                                                const {
                                                                    title: name = '',
                                                                    url: sectionLink = '',
                                                                } = section;
                                                                const _slug =
                                                                    sectionLink.includes(
                                                                        domainUrl,
                                                                    )
                                                                        ? getPathname(
                                                                              sectionLink,
                                                                          )
                                                                        : sectionLink;

                                                                return (
                                                                    <li key={name}>
                                                                        <div>
                                                                            <Link
                                                                                href={
                                                                                    _slug
                                                                                }
                                                                                title={
                                                                                    name
                                                                                }>
                                                                                {name}
                                                                            </Link>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        <li
                            key="search"
                            className={`links__item links__item--search`}
                            onClick={() => {
                                toggleSubNav('search'), activateSearch('search');
                            }}
                            onMouseEnter={() => enter('search')}
                            onMouseLeave={() => leave()}>
                            <div className="link">
                                <div
                                    className={`link__text ${
                                        activeSubName === 'search' ? 'active' : ''
                                    }`}>
                                    <SearchIcon
                                        style={{marginTop: '5px'}}
                                        width="20px"
                                        height="20px"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </MaxWidth>

            <SubNavStepCTADesktop />

            <style jsx>{`
                .top-nav {
                    width: 100%;
                    height: 64px;
                    display: block;
                    margin: 0;
                    box-shadow: var(--box-shadow);
                    background: #ffffff;
                    z-index: ${zIndex('navigation')};
                }
                .top-nav-container {
                    display: flex;
                    height: 64px;
                    align-items: center;
                    justify-content: space-between;
                }

                ul.nav-menu-container {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    list-style: none;
                    min-height: 50px;
                    justify-content: flex-end;
                }

                .link {
                    display: flex;
                    align-items: center;
                }
                .link,
                .links__item .link__text :global(a) {
                    color: var(--gray-500);
                    font-size: 16px;
                    font-weight: normal;
                    text-align: center;
                    letter-spacing: -0.16px;
                    line-height: 24px;
                }
                .links__item .link__text :global(a) {
                    text-decoration: none;
                }
                .link:hover {
                    cursor: pointer;
                    transition: all 0.25s;
                }

                .top-nav ul {
                    display: flex;
                    width: 68%;
                    height: 60px;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0;
                }
                .links__item {
                    height: auto;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    padding: 0 5px;
                    margin-right: 5px;
                }
                .links__item:hover .link__text :global(a) {
                    font-weight: 600;
                    padding: 14px 5px;
                    border-bottom: 4px solid var(--primary);
                    transition: 0.2s all;
                    fill: var(--primary);
                    stroke: var(--primary);
                    color: var(--primary);
                }
                .links__item .link__text.active :global(a) {
                    font-weight: 600;
                    padding: 14px 5px;
                    border-bottom: 4px solid var(--primary);
                    fill: var(--primary);
                    stroke: var(--primary);
                    color: var(--primary);
                }
                .link__text,
                .link__arrow {
                    display: inline-block;
                    font-weight: normal;
                }
                .link__arrow {
                    color: #000000;
                    font-size: 24px;
                    transform: rotate(90deg);
                }

                .item {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    font-size: 10px;
                    text-transform: uppercase;
                    border: 1px solid var(--gray-500);
                    border-left: none;
                    color: gray;
                    font-weight: bold;
                    text-decoration: none;
                }

                .item:hover {
                    text-decoration: underline;
                }

                .chat-now {
                    text-decoration: underline;
                    cursor: pointer;
                    font-weight: 900;
                    margin-left: 5px;
                }

                .addiction-and-rehabs {
                    display: flex;
                    justify-content: center;
                    background-color: var(--primary);
                    color: white;
                    font-weight: bold;
                }

                .ready {
                    background-color: #f0f6f7;
                }
                .logo-container {
                    max-width: 350px;
                }
                .sub-nav-seo {
                    display: none;
                }
            `}</style>
        </nav>
    );
}
