import React, {Component, useContext, useState, createRef} from 'react';
import {useRouter} from 'next/router';

import HiddenOnMobile from '../HiddenOnMobile';
import HiddenOnDesktop from '../HiddenOnDesktop';
import OutsideClick from '../OutsideClick';

import DesktopTopNavBanner from './navComponents/DesktopTopNavBanner';
import DesktopTopNav from './navComponents/DesktopTopNav';
import MobileTopNav from './navComponents/MobileTopNav';

import dynamic from 'next/dynamic';
const DesktopSubNav = dynamic(() => import('./navComponents/DesktopSubNav'));
const SideNav = dynamic(() => import('./navComponents/SideNav'));

import {AppContext} from '../../pages/_app.js';

const MAX_WIDTH = '1400px';
const LOGO_WIDTH = '150px';
const Z_INDEX = 2;

const MainNav = (props) => {
    const {menuItems} = useContext(AppContext);

    const [activeSubName, setActiveSubName] = useState('');
    const [activeSideNav, setActiveSideNav] = useState(null);

    let mainNavRef = createRef();

    const resetNav = (e) => {
        toggleSideNav(false);
        toggleSubNav(null);
    };
    const toggleSubNav = (name) => {
        setActiveSubName(name);
    };
    const closeSubNav = () => {
        if (activeSubName !== 'search') setActiveSubName(null);
    };
    const toggleSideNav = (e) => {
        if (e === false) {
            setActiveSideNav(false);
        }
        if (e && e.target) {
            setActiveSideNav(!activeSideNav);
        }
    };
    const handleSubNavOutsideClick = (e) => {
        /**
         * If user clicks inside of main nav
         * while sub nav is open, sub nav needs to
         * stay open
         */
        const {target} = e;
        if (target.nodeName === 'A' || !mainNavRef.contains(target)) {
            return !!activeSideNav && resetNav(e);
        }
    };
    const handleSideNavOutsideClick = (e) => {
        if (activeSideNav) {
            resetNav(e);
        }
    };
    const handleMainNavRef = (ref) => {
        mainNavRef = ref;
    };

    const addHierachyToMenu = (item = {}, list = []) => {
        const parents = list.filter((i) => i.menu_item_parent === item.ID.toString());
        const children = list.filter((i) => i.menu_item_parent !== item.ID.toString());

        const result = parents.map((i) => addHierachyToMenu(i, children));

        return {child_items: result, ...item};
    };

    const parents = menuItems.filter((item) => item.menu_item_parent === '0');
    const children = menuItems.filter((item) => item.menu_item_parent !== '0');
    const menu = parents.map((item) => addHierachyToMenu(item, children));

    const {banner = false, handleSubNavStepCTA} = props;

    return (
        <header onMouseLeave={closeSubNav}>
            <HiddenOnMobile>
                <DesktopTopNav
                    {...props}
                    menu={menu}
                    activeSubName={activeSubName}
                    toggleSubNav={toggleSubNav}
                    handleRef={handleMainNavRef}
                />
            </HiddenOnMobile>

            <HiddenOnDesktop>
                <MobileTopNav
                    {...props}
                    menu={menu}
                    activeSideNav={activeSideNav}
                    toggleSideNav={toggleSideNav}
                    handleRef={handleMainNavRef}
                />
            </HiddenOnDesktop>

            <OutsideClick>
                <DesktopSubNav
                    {...props}
                    menu={menu}
                    activeSubName={activeSubName}
                    toggleSubNav={toggleSubNav}
                    resetNav={resetNav}
                />
            </OutsideClick>

            <HiddenOnDesktop>
                <OutsideClick onClick={handleSideNavOutsideClick}>
                    <SideNav
                        {...props}
                        menu={menu}
                        activeSubName={activeSubName}
                        activeSideNav={activeSideNav}
                        toggleSideNav={toggleSideNav}
                        toggleSubNav={toggleSubNav}
                        resetNav={resetNav}
                    />
                </OutsideClick>
            </HiddenOnDesktop>
        </header>
    );
};

export default MainNav;
