import React, {useContext, useState} from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {AppContext} from '../../pages/_app.js';
import {BREAKPOINT, MAX_WIDTH_PX} from '../../lib/styles';
import Accordion from 'aac-components/components/Accordion';
import AktifyForm from 'aac-components/components/AktifyForm';
import Image from 'aac-components/components/Image';
import SearchByStateNav from '../SearchByStateNav';
import styled from 'styled-components';
import HipaaBadge from 'aac-components/components/HipaaBadge/index.js';

const StyledAccordion = styled.div`
    .accordion__title {
        color: #fff;
    }
    .accordion > .accordion__head::before,
    .accordion > .accordion__head::after {
        background-color: #fff;
    }
`;

const StyledAktify = styled.div`
    width: 100%;
    border-top: 1px solid var(--white);
    padding: 40px 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .aktify-form-title {
        color: var(--white);
        font-size: 20px;
        font-weight: 800;
        line-height: 22px;
        padding: 15px 0px;
    }
    label[for='Aktify_Opt_In__c'] {
        line-height: 21px;
    }
    .checkbox {
        margin-top: 4px;
    }
`;

const footerLinks = [
    {
        name: 'Treatment',
        links: [
            {
                name: 'Detox',
                href: '/treatment/detox/',
            },
            {
                name: 'Inpatient-rehab',
                href: '/treatment/inpatient-rehab/',
            },
            {
                name: 'Outpatient',
                href: '/treatment/outpatient/',
            },
        ],
    },
    {
        name: 'Resources',
        links: [
            {
                name: 'Detox Hotlines',
                href: '/detox/24-hour-hotlines/',
            },
            {
                name: 'Insurance',
                href: '/insurance/',
            },
            {
                name: 'How to pay for treatment',
                href: '/guides/how-to-pay/',
            },
        ],
    },
    {
        name: 'Admissions',
        links: [
            {
                name: 'Process',
                href: '/admissions/process/',
            },
            {
                name: 'Centers',
                href: '/aac-facilities/',
            },
            {
                name: 'Team',
                href: '/admissions/team/',
            },
        ],
    },
];

const copyrightLinks = [
    {
        name: 'Privacy Policy',
        href: '/privacy-policy/',
    },
    {
        name: 'Terms of Use',
        href: '/terms-of-use/',
    },
    {
        name: 'Sitemap',
        href: '/sitemap_index.xml',
    },
];

const FOOTER_LOGOS = [
    {
        imageLink: '/static/footer-logos/naadac-logo.png',
        pageLink: 'https://www.naadac.org/',
    },
    {
        imageLink: '/static/footer-logos/joint-commission-logo.png',
        pageLink: 'https://www.jointcommission.org/',
    },
    {
        imageLink: '/static/footer-logos/samsha-logo.png',
        pageLink: 'https://findtreatment.samhsa.gov/',
    },
    {imageLink: '/static/footer-logos/carf-logo.png', pageLink: 'http://www.carf.org/'},
    {imageLink: '/static/footer-logos/nabh-logo.png', pageLink: 'https://www.nabh.org/'},
    {imageLink: '/static/footer-logos/nih-logo.png', pageLink: 'https://www.nih.gov/'},
    {
        imageLink: '/static/footer-logos/nih-niaa-logo.png',
        pageLink: 'https://www.niaaa.nih.gov/',
    },
    {
        imageLink: '/static/footer-logos/nofas-logo.png',
        pageLink: 'https://www.nofas.org/',
    },
    {
        imageLink: '/static/footer-logos/alcohol-justice-logo.png',
        pageLink: 'https://alcoholjustice.org/',
    },
    {
        imageLink: '/static/footer-logos/anti-doping-logo.png',
        pageLink: 'https://www.wada-ama.org/',
    },
    {
        imageLink: '/static/footer-logos/ypr-logo.png',
        pageLink: 'https://youngpeopleinrecovery.org/',
    },
    {
        imageLink: '/static/footer-logos/hvmp-gold-logo.png',
        pageLink: 'https://www.hirevets.gov/',
    },
];

const Footer = (props) => {
    const date = new Date();
    const year = date.getFullYear();
    const {asPath} = useRouter();
    const hideAktifyForm = asPath.includes('/providers/') || props?.hideAktifyForm;
    const {footerMenuItems, siteOptions} = useContext(AppContext);

    let footerContent;
    if (siteOptions && siteOptions.acf) {
        const {footer_about_rehabs, footer_disclaimer} = siteOptions.acf;

        footerContent = {
            footer_about_rehabs,
            footer_disclaimer,
        };
    }

    let menuList = [];
    if (footerMenuItems) {
        const menuItems = footerMenuItems;
        const parents = menuItems.filter((item) => item.menu_item_parent === '0');
        const children = menuItems.filter((item) => item.menu_item_parent !== '0');
        menuList = parents.map((item) => {
            const subMenuItems = children.filter(
                (child) => child.menu_item_parent === item.object_id,
            );
            return {
                title: item.title,
                subMenuItems: subMenuItems.map((sub) => {
                    return {url: sub.url, title: sub.title};
                }),
            };
        });
    }

    return (
        <>
            <footer>
                <div className="footer">
                    <div className="state-section">
                        <Image
                            defer={true}
                            style={{
                                height: 'fit-content',
                                marginRight: '40px',
                                marginBottom: '40px',
                            }}
                            src="/static/wi-sub-brand-lockup-white.svg"
                            alt="American Addiction Centers Withdrawal Logo"
                        />
                        <div style={{maxWidth: '700px'}}>
                            <SearchByStateNav />
                        </div>
                    </div>

                    <div className="menu-list-container"></div>

                    {!hideAktifyForm && (
                        <StyledAktify>
                            <AktifyForm darkTheme={false} horizontal={true} />
                        </StyledAktify>
                    )}

                    <div className="footer-sections">
                        {footerLinks.map((section) => {
                            const {name, href, pathname, links} = section;

                            return (
                                <section key={name} className="desktoplink">
                                    <div className="footer-section-name">{name}</div>

                                    <ul className="footer-section-list">
                                        {links.map((link) => {
                                            const {name: linkName, href} = link;

                                            return (
                                                <li
                                                    key={linkName}
                                                    className="footer-section-listitem">
                                                    <a href={href}>{linkName}</a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </section>
                            );
                        })}
                    </div>
                    <section>
                        <div className="mobilelink">
                            {footerLinks.map((section) => (
                                <StyledAccordion key={section?.name}>
                                    <Accordion
                                        title={section?.name}
                                        children={
                                            <>
                                                <ul className="footer-section-list">
                                                    {section?.links?.map((link) => {
                                                        const {name: linkName, href} =
                                                            link;

                                                        return (
                                                            <li
                                                                key={linkName}
                                                                className="footer-section-listitem">
                                                                <a href={href}>
                                                                    {linkName}
                                                                </a>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </>
                                        }
                                    />
                                </StyledAccordion>
                            ))}
                        </div>
                    </section>
                    <div>
                        <p className="disclaimer-heading">
                            Withdrawal.net is operated by Recovery Brands LLC, a
                            subsidiary of{' '}
                            <a href="/aac/" target="_blank">
                                American Addiction Centers, Inc.
                            </a>
                        </p>
                        <p className="disclaimer">
                            GENERAL DISCLAIMER: Withdrawal.net is designed for educational
                            purposes only and is not engaged in rendering medical advice.
                            The information provided through Withdrawal.net should not be
                            used for diagnosing or treating a health problem or disease.
                            It is not a substitute for professional care. If you have or
                            suspect you may have a health problem, you should consult your
                            health care provider. The authors, editors, producers, and
                            contributors shall have no liability, obligation, or
                            responsibility to any person or entity for any loss, damage,
                            or adverse consequences alleged to have happened directly or
                            indirectly as a consequence of material on this website. If
                            you believe you have a medical emergency, you should
                            immediately call 911.
                        </p>
                    </div>
                    <div className="accreditation">
                        <ul>
                            <li>
                                <HipaaBadge />
                            </li>
                            {FOOTER_LOGOS.map((logo, i) => (
                                <li key={logo.imageLink}>
                                    <a
                                        href={logo.pageLink}
                                        rel="noopener noreferrer nofollow"
                                        target="_blank">
                                        <Image
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            src={logo.imageLink}
                                            defer
                                        />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="copyright-container p-xs">
                    <div style={{display: 'flex'}}>
                        &copy;
                        {` Withdrawal.net ${year} `}
                        <div className="dot" />
                        All Rights Reserved. &nbsp;&nbsp;{' '}
                        <span className="copyright-container__pipe">|</span>
                    </div>
                    <div className="copyrights-links">
                        {copyrightLinks.map((copyrightLink, index) => {
                            const {name, href} = copyrightLink;
                            return (
                                <div key={name}>
                                    <Link href={href}>{name}</Link>

                                    {index + 1 < copyrightLinks.length && '  '}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {/* Add placeholder space below footer to prevent sticky footer blocking footer content. Refer to code in StickyFooter */}
                <div className="footer-placeholder"></div>
            </footer>
            <style jsx>
                {`
                    footer {
                        background-color: var(--gray-600);
                        color: var(--white);
                    }
                    .footer {
                        padding: 40px;
                        margin: 0 auto;
                        max-width: ${MAX_WIDTH_PX};
                    }
                    .state-section {
                        display: flex;
                    }
                    .menu-list-container {
                        display: flex;
                        justify-content: space-between;
                        background-color: var(--secondary-600);
                    }
                    .menu-column {
                        width: 200px;
                        display: flex;
                        flex-direction: column;
                    }
                    .menu-column .menu-title {
                        margin-bottom: 16px;
                    }
                    .menu-column .menu-item {
                        color: var(--white);
                        margin-bottom: 8px;
                        display: block;
                    }
                    .copyright-container {
                        border-top: 1px solid var(--white);
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        padding: 16px;
                    }
                    .copyright-container :global(a) {
                        margin-right: 16px;
                        color: var(--white);
                    }
                    .desktoplink {
                        display: none;
                    }
                    .mobilelink {
                        display: block;
                    }
                    section {
                        padding: 20px;
                        flex-basis: 0;
                        flex-grow: 1;
                    }
                    section :global(a.footer-section-name),
                    .footer-section-name {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 600;
                        height: 24px;
                        letter-spacing: -0.04em;
                        line-height: 18px;
                        margin-bottom: 20px;
                    }
                    section :global(a.footer-section-name:hover) {
                        text-decoration: underline;
                    }
                    section ul {
                        margin: 0 0 0 20px;
                        padding: 0;
                    }
                    section li {
                        margin: 8px 0;
                    }
                    section :global(a) {
                        color: #fff;
                        font-size: 12px;
                        font-weight: normal;
                        height: 26px;
                        letter-spacing: -0.16px;
                        line-height: 25px;
                        text-decoration: none;
                    }
                    .disclaimer-heading {
                        margin: 30px 0 10px;
                        text-align: center;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 400;
                    }
                    .disclaimer-heading > :global(a) {
                        color: var(--white);
                        text-decoration: underline;
                    }
                    .disclaimer {
                        color: rgba(255, 255, 255, 0.5);
                        font-size: 12px;
                        font-weight: normal;
                        letter-spacing: -0.16px;
                        line-height: 16px;
                        padding: 0px 15px;
                        text-align: center;
                    }
                    .copyrights-links div {
                        display: inline-block;
                        margin-left: 10px;
                    }
                    .dot {
                        width: 4px;
                        height: 4px;
                        border-radius: 2px;
                        background-color: var(--white);
                        margin: 11px 7px 7px 7px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .footer-sections {
                            display: flex;
                            justify-content: space-between;
                        }
                        footer {
                            padding-bottom: 20px;
                        }
                        .desktoplink {
                            display: block;
                        }
                        .mobilelink {
                            display: none;
                        }
                        .menu-list-container {
                            flex-direction: column;
                            margin-top: 40px;
                        }
                    }
                    @media (max-width: ${BREAKPOINT}) {
                        .footer {
                            padding: 40px 15px;
                        }
                        .state-section {
                            flex-wrap: wrap;
                        }
                        .copyright-container__pipe {
                            display: none;
                        }
                        .menu-list-container {
                            flex-direction: column;
                        }
                        .menu-column {
                            width: 100%;
                        }
                        .disclaimer-heading,
                        .disclaimer {
                            text-align: left;
                            padding: 0px;
                        }
                        .copyright-container {
                            border-top: 1px solid var(--white);
                            display: flex;
                            flex-direciton: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                        }
                        .copyright-container > div {
                            font-size: 14px;
                        }
                        .copyright-container :global(a) {
                            margin-bottom: 16px;
                        }
                        .copyrights-links {
                            flex-direction: row;
                        }
                        .copyrights-links div {
                            margin-left: 0px;
                        }
                        .footer-placeholder {
                            padding-bottom: 25vh;
                        }
                    }

                    .accreditation {
                        padding: 24px 0;
                    }
                    .accreditation ul {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                    .accreditation li {
                        margin: 10px;
                        list-style: none;
                        flex-basis: 60px;
                    }
                `}
            </style>
        </>
    );
};

export default Footer;
