import React, {useContext} from 'react';
import Button from 'aac-components/components/Button';
import {AppContext} from '../../pages/_app';
import {BREAKPOINT} from '../../lib/styles';

const StickyFooter = (props) => {
    let {contentSegment, isMobile} = useContext(AppContext);

    let btnText = 'Check Insurance';
    let btnLink = '/verify-insurance/';

    if (Array.isArray(contentSegment) && contentSegment.length) {
        contentSegment = contentSegment[0]?.content_segment;
    }

    const isConversionPage = contentSegment === 'conversion-pages';
    const isFacilityPage =
        contentSegment === 'facility-pages' || contentSegment === 'local-pages';
    const isTreatmentDir =
        contentSegment === 'info-treatment' ||
        contentSegment === 'directory-result-state' ||
        contentSegment === 'directory-result-city';

    switch (contentSegment) {
        case 'info-pr':
            btnText = 'Detox Guide';
            btnLink = '/detox/';

            break;

        case 'info-drug-illegal':
        case 'info-drug-otc':
        case 'info-alcohol':
            btnText = 'Find Treatment';
            btnLink = '/local-detox/';

            break;

        case 'info-treatment':
        case 'directory-result-state':
        case 'directory-result-city':
            btnText = 'Treatment Centers';
            btnLink = '/aac-facilities/';

            break;

        case 'facility-pages':
            (btnText = 'Check Insurance'), (btnLink = '/verify-insurance/');
            break;
        case 'local-pages':
            btnText = 'Start Treatment';
            btnLink = '/aac/admissions/process/';

            break;

        case 'conversion-pages':
            (btnText = 'Check Insurance'), (btnLink = '/verify-insurance/');

            break;
    }

    return (
        <>
            <div className="cta-sticky-footer">
                <div className="cta-sticky-footer-call">
                    <Button
                        href={btnLink}
                        style={{
                            minWidth: '100%',
                            fontWeight: 'bold',
                            textDecoration: 'none',
                        }}>
                        {btnText}
                    </Button>
                </div>

                <style jsx>{`
                    .cta-sticky-footer {
                        background-color: var(--primary-300);
                        height: 48px;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex: 2;
                    }
                    .cta-sticky-footer-call {
                        width: 100%;
                        margin: 0px;
                        text-align: center;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .cta-sticky-footer {
                            color: #fff;
                            text-align: center;
                        }
                    }
                `}</style>
            </div>
        </>
    );
};
export default StickyFooter;
