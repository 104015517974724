import React from 'react';
import {BREAKPOINT, MAX_WIDTH_PX} from '../../lib/styles';

const MaxWidth = (props) => {
    return (
        <div className="page-max-width" style={props.style}>
            <div className="page-content" style={props.pageContentStyle}>
                {props.children}
            </div>
            <style jsx>{`
                div {
                    margin-right: auto;
                    margin-left: auto;
                    max-width: ${MAX_WIDTH_PX};
                    padding: 0px;
                }
                .page-content {
                    height: 100%;
                    margin: 0;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .page-content {
                        margin: 0 15px;
                    }
                }
            `}</style>
        </div>
    );
};

export default MaxWidth;
