export default function Menu(props) {
    const {fill = '#1a1a1a', width = 30, height = 30} = props;
    return (
        <svg width={width} height={height} viewBox="0 0 25 25" {...props}>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="SVGs"
                    transform="translate(-827.000000, -118.000000)"
                    fillRule="nonzero">
                    <g id="icon-menu" transform="translate(827.000000, 118.000000)">
                        <rect
                            id="Rectangle"
                            fill="#000000"
                            opacity="0"
                            transform="translate(12.500000, 12.500000) rotate(180.000000) translate(-12.500000, -12.500000) "
                            x="-1.85037171e-15"
                            y="1.85037171e-15"
                            width="25"
                            height="25"></rect>
                        <rect
                            id="Rectangle"
                            fill={fill}
                            x="3.125"
                            y="11.4583333"
                            width="18.75"
                            height="2.08333333"
                            rx="0.989583333"></rect>
                        <rect
                            id="Rectangle"
                            fill={fill}
                            x="3.125"
                            y="16.6666667"
                            width="18.75"
                            height="2.08333333"
                            rx="0.989583333"></rect>
                        <rect
                            id="Rectangle"
                            fill={fill}
                            x="3.125"
                            y="6.25"
                            width="18.75"
                            height="2.08333333"
                            rx="0.989583333"></rect>
                    </g>
                </g>
            </g>
        </svg>
    );
}
