import React, {useState} from 'react';
import {Regions} from '@aac/directory';
import IconLocationMarker from '../Svgs/IconLocationMarker';
import {BREAKPOINT} from '../../lib/styles';
import Link from 'next/link';

const SearchByStateNav = ({letter = 'A', modifier = ''}) => {
    const regions = new Regions();
    const [activeLetter, setActiveLetter] = useState(letter);

    return (
        <>
            <div className={`search-by-state ${modifier ? modifier : ''}`}>
                <div className="title-sm">Browse Detox Centers by State</div>

                <ul className="list-alphabetic">
                    {regions.letters().map((letter) => (
                        <li
                            key={letter}
                            className={letter === activeLetter ? 'is-active' : null}>
                            <a onClick={() => setActiveLetter(letter)}>{letter}</a>
                        </li>
                    ))}
                </ul>

                <ul className="list-places">
                    {Object.keys(regions.REGIONS).map((state, index) => {
                        if (state?.trim()?.toLowerCase() === 'district of columbia')
                            return false;

                        return (
                            <li
                                key={index}
                                className={
                                    state.trim().charAt(0) === activeLetter
                                        ? 'is-active'
                                        : null
                                }>
                                <Link
                                    href={`/local-detox/${state
                                        .replace(/\s+/g, '-')
                                        .toLowerCase()}/`}
                                    className="link-ico">
                                    <IconLocationMarker
                                        fill="var(--primary)"
                                        style={{marginRight: '10px'}}
                                    />
                                    <p className="list-places__state">{state}</p>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <style jsx>
                {`
                    .search-by-state h4,
                    .title-sm {
                        color: var(--white);
                        margin: 0px 0 24px 0;
                        padding-left: 20px;
                    }
                    .light-theme .search-by-state h4,
                    .light-theme .title-sm {
                        color: var(--gray-500);
                    }
                    .list-alphabetic {
                        list-style-type: none;
                    }
                    .list-alphabetic li {
                        display: inline-block;
                        vertical-align: middle;
                        border-radius: 4px;
                        overflow: hidden;
                        transition: background-color 0.3s;
                    }
                    .list-alphabetic li:hover {
                        background-color: var(--primary-300);
                        cursor: pointer;
                    }
                    .light-theme .list-alphabetic li:hover :global(a) {
                        color: var(--white);
                        cursor: pointer;
                    }
                    .list-alphabetic li + li {
                        margin-left: 15px;
                    }
                    .list-alphabetic :global(a) {
                        display: block;
                        text-decoration: none;
                        width: 20px;
                        height: 20px;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 18px;
                        text-align: center;
                        color: var(--white);
                        padding: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                    }
                    .light-theme .list-alphabetic :global(a) {
                        color: var(--gray-500);
                    }
                    .list-alphabetic .is-active :global(a) {
                        background-color: var(--primary);
                        color: var(--white);
                    }
                    .light-theme .list-alphabetic .is-active :global(a) {
                        background-color: var(--interactive);
                        color: var(--white);
                    }
                    .light-theme .list-alphabetic li:hover {
                        background-color: var(--interactive);
                    }

                    .list-places {
                        margin-top: 16px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: baseline;
                        margin-bottom: 20px;
                    }
                    .list-places li {
                        display: none;
                    }
                    .list-places .is-active {
                        display: flex;
                        vertical-align: middle;
                        font-weight: normal;
                        padding: 14px;
                    }
                    .list-places li :global(a) {
                        display: flex;
                        align-items: center;
                        padding: 15px 0;
                        text-decoration: none;
                    }
                    .list-places li :global(a:hover) {
                        text-decoration: underline;
                    }
                    .list-places li p {
                        color: var(--white);
                        margin: 0;
                    }
                    .light-theme .list-places li p {
                        color: var(--gray-500);
                    }
                    .list-places__state {
                        font-size: 12px;
                        line-height: 18px;
                    }

                    .list-alphabetic li,
                    .list-alphabetic li + li {
                        margin-bottom: 4px;
                        margin-left: 4px;
                    }

                    @media screen and (min-width: ${BREAKPOINT}) {
                        .list-alphabetic li,
                        .list-alphabetic li + li {
                            margin-bottom: 6px;
                            margin-left: 6px;
                        }
                        .list-alphabetic :global(a) {
                            font-size: 14px;
                            padding: 0;
                        }
                        .list-places {
                            margin-left: -45px;
                        }
                        .list-places .is-active {
                            margin: 12px 0 12px 40px;
                        }
                        .list-places li :global(a) {
                            padding: 8px 0;
                        }
                    }
                `}
            </style>
        </>
    );
};

export default SearchByStateNav;
