import Link from 'next/link';
import React, {useState} from 'react';
import css from 'styled-jsx/css';

import OutsideClick from '../../OutsideClick';
import HiddenOnDesktop from '../../HiddenOnDesktop';
import Image from '../../Image';
import MaxWidth from '../../MaxWidth';
import MenuIcon from '../../Svgs/MenuIcon';
import Close from '../../Svgs/Close';
import SearchIcon from '../../Svgs/SearchIcon';

import {BREAKPOINT} from '../../../lib/styles';

import dynamic from 'next/dynamic';
const SiteSearch = dynamic(() => import('../../SiteSearch'));

const MAX_WIDTH = '1350px';
const LOGO_WIDTH = '208px';
const LOGO_HEIGHT = '70px';
const NAV_HEIGHT = '130px';
export const NAV_HEIGHT_MOBILE = '56px';

const MobileTopNav = (props) => {
    const [search, setSearch] = useState(false);
    const {activeSideNav} = props;

    return (
        <nav ref={props.handleRef}>
            <MaxWidth pageContentStyle={{margin: 0}}>
                <HiddenOnDesktop>
                    <div className="main-nav mobile">
                        <div className="container">
                            <div className="logo">
                                <Link href="/" title="Home" style={{margin: '0 auto'}}>
                                    <Image
                                        src="/static/wd-sub-brand-lockup-color.svg"
                                        alt="American Addiction Center - Trademark Logo"
                                    />
                                </Link>
                            </div>

                            <div
                                className="search-wrapper"
                                onClick={() => setSearch(true)}>
                                <SearchIcon
                                    style={{marginTop: '5px'}}
                                    width="20px"
                                    height="20px"
                                    stroke={search ? 'var(--primary)' : '#000'}
                                    strokeWidth={1}
                                />
                            </div>
                            <div className="menu-wrapper">
                                <ul className="icons">
                                    <li onClick={props.toggleSideNav}>
                                        <div className="item-menu">
                                            {activeSideNav ? (
                                                <Close
                                                    fill={'#fff'}
                                                    stroke={'#fff'}
                                                    width={24}
                                                    height={24}
                                                />
                                            ) : (
                                                <MenuIcon
                                                    fill={'#fff'}
                                                    width={24}
                                                    height={24}
                                                />
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {search && (
                        <OutsideClick onClick={() => setSearch(false)}>
                            <div className="search-box-wrapper">
                                <SiteSearch
                                    icon={true}
                                    style={{padding: 0, height: '56px'}}
                                    overrides={css.resolve`
                                        .site-search-container {
                                            margin: 0;
                                            height: 56px;
                                        }
                                        .site-search-container .input-container {
                                            padding: 10px;
                                        }
                                    `}
                                />
                            </div>
                        </OutsideClick>
                    )}
                </HiddenOnDesktop>
            </MaxWidth>

            <style jsx>
                {`
                    .main-nav {
                        background: #ffffff;
                        margin: 0 auto;
                        height: ${NAV_HEIGHT_MOBILE};
                        box-shadow: var(--box-shadow);
                        border-radius: 0;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .main-nav {
                            height: ${NAV_HEIGHT};
                        }
                    }
                    .main-nav.desktop {
                        display: flex;
                    }
                    .main-nav.mobile {
                        display: flex;
                        padding: 5px 0px 5px 10px;
                        justify-content: space-between;
                    }
                    .container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            justify-content: space-between;
                        }
                    }
                    .main-nav ul {
                        display: flex;
                        list-style: none;
                        width: 100%;
                        padding: 0;
                    }
                    .main-nav.desktop :global(a) {
                        color: var(--primary);
                        font-weight: bold;
                        font-size: 12px;
                        margin-right: 20px;
                    }
                    .call-btn {
                        background-color: var(--primary);
                        border: 1px solid #fff;
                        border-radius: 5px;
                        color: #fff;
                        font-weight: bold;
                        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
                        padding: 15px 30px;
                        font-size: 13px;
                        max-width: 200px;
                        margin: auto 0 auto auto;
                        text-align: center;
                    }
                    .call-btn:hover {
                        opacity: 0.8;
                        cursor: pointer;
                        transition: all 0.25s;
                    }

                    .search-wrapper {
                        margin: 0 10px;

                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        flex: 1;
                    }

                    .menu-wrapper {
                        height: 56px;
                        min-width: 56px;
                        background-color: var(--primary);
                        border-radius: 0px;
                        padding-top: 5px;
                    }
                    .logo {
                        margin-right: 10px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .logo {
                            margin: 0 auto;
                        }
                    }
                    .icons {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .icons .item {
                        color: var(--primary);
                        text-decoration: none;
                        font-size: 9px;
                        font-weight: 900;
                        text-align: center;
                        text-transform: uppercase;
                        display: flex;
                    }
                    .icons .item:hover {
                        cursor: pointer;
                        transition: all 0.25s;
                    }

                    .search-box-wrapper {
                        background: #055276;
                        border-radius: 0px;
                        height: 72px;
                        width: 100%;
                        padding: 8px 15px;
                    }
                `}
            </style>
        </nav>
    );
};

export default MobileTopNav;
