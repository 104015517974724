export default function Close(props) {
    return (
        <svg
            viewBox="0 0 25 25"
            width={props.width || 25}
            height={props.height || 25}
            fill={props.fill || '#000'}
            stroke={props.stroke || '#000'}
            strokeWidth={props.strokeWidth || '1'}
            style={props.style}>
            <title>{props.title || 'Close'}</title>
            <g id="Page-1" fillRule="evenodd">
                <g id="SVGs" transform="translate(-902.000000, -118.000000)">
                    <g id="icon-close" transform="translate(902.000000, 118.000000)">
                        <rect
                            id="Rectangle"
                            fill="#000000"
                            fillRule="nonzero"
                            opacity="0"
                            transform="translate(12.500000, 12.500000) rotate(180.000000) translate(-12.500000, -12.500000) "
                            x="0"
                            y="0"
                            width="25"
                            height="25"></rect>
                        <path
                            d="M13.96875,12.5 L18.4479167,8.03125 C18.8563773,7.6227894 18.8563773,6.96054393 18.4479167,6.55208333 C18.0394561,6.14362274 17.3772106,6.14362274 16.96875,6.55208333 L12.5,11.03125 L8.03125,6.55208333 C7.6227894,6.14362275 6.96054394,6.14362275 6.55208334,6.55208334 C6.14362275,6.96054394 6.14362275,7.6227894 6.55208333,8.03125 L11.03125,12.5 L6.55208333,16.96875 C6.35488266,17.1643402 6.24396008,17.4305859 6.24396008,17.7083333 C6.24396008,17.9860808 6.35488266,18.2523265 6.55208333,18.4479167 C6.74767354,18.6451173 7.01391923,18.7560399 7.29166667,18.7560399 C7.5694141,18.7560399 7.83565979,18.6451173 8.03125,18.4479167 L12.5,13.96875 L16.96875,18.4479167 C17.1643402,18.6451173 17.4305859,18.7560399 17.7083333,18.7560399 C17.9860808,18.7560399 18.2523265,18.6451173 18.4479167,18.4479167 C18.6451173,18.2523265 18.7560399,17.9860808 18.7560399,17.7083333 C18.7560399,17.4305859 18.6451173,17.1643402 18.4479167,16.96875 L13.96875,12.5 Z"
                            id="Path"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}
