import {zIndex} from 'aac-components/utils/helpers';
import React, {useState, useEffect} from 'react';

// note: meant to be renderd on mobile only
const SlideUpStickyFooter = ({
    showOnLoad = false,
    title = '',
    children = React.ReactNode,
    backgroundColor = 'var(--interactive-300, #2459a9)',
}) => {
    const [showCtaHeader, toggleCtaHeader] = useState(showOnLoad);
    const [showCtaContent, toggleCtaContent] = useState(false);
    useEffect(() => {
        // don't bother with header visability if showOnLoad
        if (showOnLoad) return undefined;

        // on scroll past 100px - show cta
        const onScroll = () => {
            const windowHeight = window && window.pageYOffset;
            if (windowHeight >= 100) {
                toggleCtaHeader(true);
            } else {
                toggleCtaHeader(false);
            }
        };

        window.addEventListener('scroll', onScroll);
        // return a function to remove event listeners
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <div className="container">
            <div
                className={`cta ${showCtaHeader && 'show-header'} ${
                    showCtaContent && 'show-content'
                }`}>
                <div
                    className="cta__header"
                    onClick={() => toggleCtaContent((prevInputState) => !prevInputState)}>
                    <div className="cta__icon--container">
                        <div className="cta__icon" />
                    </div>
                    <div
                        className="cta__title"
                        dangerouslySetInnerHTML={{__html: title}}
                    />
                </div>
                <div className="cta__content">{children}</div>
            </div>
            <style jsx>{`
                :global(body),
                :global(html) {
                    overflow-y: ${showCtaContent ? 'hidden' : 'auto'};
                }

                .cta {
                    display: block;
                    position: fixed;
                    bottom: 0;
                    height: 0;
                    right: 0;
                    left: 0;
                    min-height: 0;
                    width: 100%;
                    overflow: hidden;
                    z-index: ${zIndex('floatingActionButton')};
                    transition: all 0.5s;
                    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08),
                        0px -16px 28px -1px rgba(0, 0, 0, 0.18);
                    border-radius: 32px 32px 0 0;
                }
                .cta.show-header {
                    height: 95px;
                    transition: all 0.5s;
                }
                .cta.show-header.show-content {
                    height: 85vh;
                    max-height: 90%;
                    z-index: 101;
                    transition: all 0.5s;
                }
                .cta__header {
                    background: ${backgroundColor};
                    height: 95px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    color: #fff;
                    border-radius: 32px 32px 0 0;
                }
                .cta__icon--container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                }
                .cta__icon {
                    height: 5px;
                    border-radius: 4em;
                    width: 40px;
                    background: rgba(255, 255, 255, 0.5);
                    display: flex;
                    justify-content: center;
                }
                .cta__title {
                    padding: 0 16px;
                    text-align: center;
                    font-size: 20px;
                }
                .cta__title > span {
                    font-weight: bold;
                    text-decoration: underline;
                }
                .cta__content {
                    background: #fff;
                    margin-top: 95px;
                    padding: 15px;
                }
                .cta.show-header.show-content > .cta__content {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    overflow-y: scroll;
                    min-width: 100%;
                }
            `}</style>
        </div>
    );
};

export default SlideUpStickyFooter;
