import React from 'react';
import Button from '../../../Button';
import NavList from '../NavList';
import Router from 'next/router';

import MenuIcon from '../../../Svgs/Menu';
import Close from '../../../Svgs/Close';
import Phone from '../../../Svgs/Phone';

import CallTrackingNumber from '../../../CallTrackingNumber';
import {HEADER_MOBILE_HEIGHT} from '../../../Header';

import {zIndex} from 'aac-components/utils/helpers';

class MobileNav extends React.PureComponent {
    state = {
        display: false,
        mounted: true,
    };

    // close mobile nav when someone navigates
    closeOnRouteChange = (url) => {
        this.handleClose();
    };

    componentDidMount() {
        Router.events.on('routeChangeComplete', this.closeOnRouteChange);
    }

    componentWillUnmount() {
        this.setState({mounted: false});
        Router.events.off('routeChangeComplete', this.closeOnRouteChange);
    }

    toggleMobileNav = (e) => {
        this.setState((prevState) => ({
            display: !prevState.display,
        }));
    };

    handleClose = (e) => {
        this.state.mounted &&
            this.state.display &&
            this.setState({
                display: false,
            });
    };

    render() {
        const {list} = this.props;
        const {display} = this.state;

        return (
            <nav style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                <div className="mobile-nav__container">
                    <div className="mobile-nav__cta">
                        <Button>
                            <CallTrackingNumber>
                                <div className="contact-icon">
                                    <Phone fill="#fff" width={14} height={14} />
                                </div>
                                <span className="contact-text">Contact</span>
                            </CallTrackingNumber>
                        </Button>
                    </div>
                    <div className="mobile-nav__menu" onClick={this.toggleMobileNav}>
                        {display ? <Close /> : <MenuIcon fill={'var(--primary)'} />}
                    </div>
                </div>
                <div className={`mobile-nav__dropdown ${display ? 'show' : ''}`}>
                    <NavList list={list} classPrefix="mobile-nav" isMobile={true} />
                </div>
                <style jsx global="true">{`
                    .mobile-nav__cta button {
                        padding: 6px 24px;
                        margin-right: 12px;
                    }
                    @media screen and (min-width: 768px) {
                        .mobile-nav__cta button {
                            padding: 12px 24px;
                        }
                    }
                `}</style>
                <style jsx>{`
                    .mobile-nav__container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 0 0 15px;
                    }
                    .contact-icon {
                        transform: rotate(270deg);
                    }
                    .contact-text {
                        display: none;
                    }
                    @media screen and (min-width: 768px) {
                        .contact-icon {
                            display: none;
                        }
                        .contact-text {
                            display: block;
                        }
                    }
                    .mobile-nav__menu {
                        display: flex;
                        min-width: 30px;
                    }
                    .mobile-nav__dropdown {
                        background-color: #fff;
                        position: fixed;
                        overflow-y: auto;
                        overflow-x: hidden;
                        left: 0;
                        top: ${HEADER_MOBILE_HEIGHT};
                        width: 100%;
                        z-index: ${zIndex('navigation')};
                        max-height: 0;
                        transform: scaleY(0);
                        transform-origin: top;
                        transition: max-height 0.3s, transform 0.3s,
                            -webkit-transform 0.3s;
                    }
                    .mobile-nav__dropdown.show {
                        max-height: 100vh;
                        transform: scaleY(1);
                    }
                `}</style>
            </nav>
        );
    }
}

export default MobileNav;
