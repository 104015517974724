import React, {createContext, useState} from 'react';
import Link from 'next/link';
import Image from '../Image';
import {BREAKPOINT} from '../../lib/styles';
import Navigation from '../Navigation';
import MaxWidth from '../MaxWidth';
import {zIndex} from 'aac-components/utils/helpers';

export const HEADER_MOBILE_HEIGHT = '70px';
export const HEADER_DESKTOP_HEIGHT = '70px';
export const SearchBarContext = createContext({isOpen: false});

const Header = (props) => {
    const [isOpen, toggleSearchBar] = useState(false);

    return (
        <header>
            <SearchBarContext.Provider value={{isOpen, toggleSearchBar}}>
                <section className="header-section">
                    <MaxWidth style={{height: '100%'}}>
                        <div className="header-content">
                            <Link href="/" className="logo-link">
                                <Image
                                    src="/static/arh-logo.svg"
                                    style={{height: '100%'}}
                                />
                            </Link>
                            <Navigation />
                        </div>
                    </MaxWidth>
                </section>
            </SearchBarContext.Provider>
            <style jsx>{`
                header {
                    background-color: #fff;
                    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: space-between;
                    height: ${HEADER_MOBILE_HEIGHT};
                    position: sticky;
                    top: 0;
                    z-index: ${zIndex('navigation')};
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    header {
                        min-height: ${HEADER_DESKTOP_HEIGHT};
                    }
                }
                .header-section {
                    background-color: #fff;
                    padding: 10px 0;
                    position: relative;
                    height: 100%;
                }
                .header-content {
                    display: flex;
                    justify-content: space-between;
                    height: 100%;
                    align-items: center;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .header-content {
                        padding: 0 15px;
                    }
                }
                .header-content :global(.logo-link) {
                    height: 40px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .header-content :global(.logo-link) {
                        height: 50px;
                    }
                }
            `}</style>
        </header>
    );
};

export default Header;
