import React, {useContext} from 'react';
import HiddenOnDesktop from '../HiddenOnDesktop';
import HiddenOnMobile from '../HiddenOnMobile';
import MobileNav from './components/MobileNav';
import NavList from './components/NavList';
import {AppContext} from '../../pages/_app.js';

function addHierachyToMenu(item = {}, list = []) {
    const parents = list.filter((i) => i.menu_item_parent === item.ID.toString());
    const children = list.filter((i) => i.menu_item_parent !== item.ID.toString());
    const result = parents.map((i) => addHierachyToMenu(i, children));
    return {child_items: result, ...item};
}

const Navigation = (props) => {
    const {menuItems} = useContext(AppContext);
    // TODO fix menu error handling
    return null;

    const parents = menuItems.filter((item) => item.menu_item_parent === '0');
    const children = menuItems.filter((item) => item.menu_item_parent !== '0');
    const menu = parents.map((item) => addHierachyToMenu(item, children));

    return (
        <>
            <HiddenOnDesktop>
                <MobileNav list={menu} />
            </HiddenOnDesktop>
            <HiddenOnMobile>
                <nav
                    style={{
                        padding: '0 5px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                    <NavList list={menu} classPrefix="desktop-nav" />
                </nav>
            </HiddenOnMobile>
        </>
    );
};

export default Navigation;
