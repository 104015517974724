import React, {useEffect} from 'react';
import MaxWidth from '../../MaxWidth';
import Image from '../../Image';
import HelpLineInfo from '../../HelpLineInfo';

import {swappedCallTrackingNumber} from '../../CallTrackingNumberWrapper';
import {zIndex} from 'aac-components/utils/helpers';

const TopNavBanner = () => {
    const callTrackingNumber = swappedCallTrackingNumber();

    useEffect(() => {
        if (typeof window !== 'undefined' && window.CallTrk) {
            window.CallTrk.swap();
        }
    });

    return (
        <nav className="top-nav-banner">
            <MaxWidth style={{maxWidth: '1400px'}}>
                <div className="top-nav-banner-container">
                    <ul>
                        <li>
                            <div className="wrapper">
                                <div className="item">
                                    <label className="cta-text">
                                        Call American Addiction Centers for help today.
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="wrapper">
                                <div className="item">
                                    <div>
                                        <a
                                            className="cta-text-number"
                                            title="Home"
                                            href={callTrackingNumber?.href}
                                            style={{
                                                color: 'transparent',
                                                textDecoration: 'none',
                                            }}>
                                            <Image
                                                src="/static/icon-phone-white.svg"
                                                alt="Call"
                                                style={{
                                                    height: '20px',
                                                    width: '20px',
                                                    margin: '2px 0',
                                                }}
                                            />
                                            <span
                                                className="cta-text"
                                                style={{fontWeight: 600}}>
                                                &nbsp;
                                                {callTrackingNumber?.display}
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="wrapper">
                                <div className="item">
                                    <HelpLineInfo
                                        style={{height: '16px', width: '120px'}}
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </MaxWidth>

            <style global="true" jsx>
                {`
                    .cta-text a.calltracking_number {
                        color: #fff;
                        text-decoration: none;
                        font-family: var(--title-font);
                        font-size: 12px;
                        font-weight: 600;
                        letter-spacing: -0.16px;
                        line-height: 16px;
                    }
                `}
            </style>

            <style jsx>{`
                .top-nav-banner {
                    display: block;
                    background: #1b222c;
                    width: 100%;
                    border-radius: 0px;
                    margin: 0;
                    z-index: ${zIndex('navigation')};
                }
                .top-nav-banner-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                }
                .top-nav-banner-container ul {
                    display: flex;
                    width: 520px;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    align-items: center;
                    justify-content: space-between;
                    margin-left: 48%;
                }

                .top-nav-banner-container ul li .cta-text {
                    font-family: var(--title-font);
                    font-size: 12px;
                    font-weight: normal;
                    color: #ffffff;
                    letter-spacing: -0.16px;
                    line-height: 16px;
                }

                a.cta-text-number {
                    color: #fff;
                    text-decoration: none;
                    font-family: var(--title-font);
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: -0.16px;
                    line-height: 16px;
                    color: transparent;
                    text-decoration: none;
                }
            `}</style>
        </nav>
    );
};

export default React.memo(TopNavBanner);
