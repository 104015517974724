import Link from 'next/link';
import React from 'react';
import {useRouter} from 'next/router';

const NavList = (props) => {
    const {list, classPrefix = ''} = props;
    const router = useRouter();
    const {
        query: {slug: currentSlug},
    } = router;

    return (
        <>
            <ul className={`${classPrefix}__ul`}>
                {list &&
                    list.map((link, index) => {
                        let {title, url, object: postType} = link;

                        // currently routed by post type & only support links without basePath
                        //  for custom templates or to route by url, that would need to be added in here.
                        // otherwise unfound templates in the pages directory will default to serverside navigation
                        const linkPaths = url.split('withdrawal.net/');
                        let slug = postType === 'custom' ? linkPaths[0] : linkPaths[1];
                        slug = slug.replace(/\//g, '');
                        if (slug === 'rehab-news') {
                            postType = 'blog';
                        }
                        const href = {
                            pathname: '/' + postType,
                            query: {slug},
                        };

                        return (
                            <li
                                className={`${classPrefix}__li ${
                                    currentSlug === slug ? 'current-page' : ''
                                }`}
                                key={index}>
                                <Link
                                    href={`/${slug}/`}
                                    title={title}
                                    className={`${classPrefix}__link`}>
                                    {title}
                                </Link>
                            </li>
                        );
                    })}
            </ul>
            <style jsx>
                {`
                    .desktop-nav__ul {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                    .desktop-nav__li {
                        display: flex;
                        align-items: center;
                        position: relative;
                        padding: 0 7.5px;
                    }
                    .desktop-nav__li:nth-child(5) {
                        border-right: 2px solid var(--gray-300);
                        padding-right: 15px;
                    }
                    .desktop-nav__li:nth-child(6) {
                        padding-left: 15px;
                    }
                    @media screen and (min-width: 1100px) {
                        .desktop-nav__li {
                            padding: 0 15px;
                        }
                        .desktop-nav__li:nth-child(5) {
                            padding-right: 30px;
                        }
                        .desktop-nav__li:nth-child(6) {
                            padding-left: 30px;
                        }
                    }
                    .desktop-nav__li:last-child {
                        padding-right: 0;
                    }
                    :global(.desktop-nav__link) {
                        color: #008dc0;
                        font-family: var(--title-font);
                        font-size: 16px;
                        line-height: 25px;
                        font-weight: bold;
                        cursor: pointer;
                        text-decoration: none;
                        border-bottom: 2px solid #fff;
                    }
                    :global(.desktop-nav__link:hover) {
                        border-color: #008dc0;
                    }
                    .mobile-nav__ul {
                        background-color: var(--primary-100);
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        flex-flow: column nowrap;
                    }
                    .mobile-nav__li {
                        display: flex;
                        background-color: var(--primary-100);
                        align-items: center;
                        border: 1px solid rgba(0, 106, 181, 0.09);
                    }
                    .mobile-nav__li:last-child {
                        border: none;
                    }
                    :global(.mobile-nav__link) {
                        align-items: center;
                        display: flex;
                        flex-basis: 100%;
                        background-color: var(--primary-100);
                        color: var(--primary);
                        font-size: 15px;
                        font-family: var(--title-font);
                        font-weight: bold;
                        padding: 13px 20px;
                        text-decoration: none;
                        transition: all 0.2s linear;
                    }
                    .current-page :global(.mobile-nav__link),
                    :global(.mobile-nav__link:hover),
                    :global(.mobile-nav__link:active),
                    :global(.mobile-nav__link:focus) {
                        background-color: var(--primary);
                        color: #fff;
                    }
                `}
            </style>
        </>
    );
};

export default NavList;
