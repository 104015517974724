import React from 'react';

export default class OutsideClick extends React.Component {
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.props.onMouseDown) {
                this.props.onMouseDown(event);
            } else if (this.props.onMouseUp) {
                this.props.onMouseUp(event);
            } else if (this.props.onClick) {
                this.props.onClick(event);
            }
        }
    };

    render() {
        const {style} = this.props;

        return (
            <div style={style ? style : {}} ref={this.setWrapperRef}>
                {this.props.children}
            </div>
        );
    }
}
