import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from 'next/router';
import Image from 'aac-components/components/Image';

import SecondButton from '../StickyFooterCta/SecondButton';
import {swappedCallTrackingNumber} from '../CallTrackingNumberWrapper';
import {AppContext} from '../../pages/_app';
import Button from 'aac-components/components/Button';

const NeedHelpCta = (props) => {
    const {contactLoopChat} = props;
    let {contentSegment, isMobile} = useContext(AppContext);

    if (Array.isArray(contentSegment) && contentSegment?.length) {
        contentSegment = contentSegment[0]?.content_segment;
    }

    const isConversionPage = contentSegment === 'conversion-pages';
    const isFacilityPage =
        contentSegment === 'facility-pages' || contentSegment === 'local-pages';

    const [showButton, setShowButton] = useState(false);
    const [btnText, setBtnText] = useState('');
    const [btnLink, setBtnLink] = useState('');

    const callTrackingNumber = swappedCallTrackingNumber();

    useEffect(() => {
        switch (contentSegment) {
            case 'info-pr':
            case 'info-drug-illegal':
            case 'info-drug-otc':
            case 'info-alcohol':
                setShowButton(true);
                setBtnText('24/7 Text Support');
                setBtnLink('/admissions/team/text-support/');

                break;

            case 'info-treatment':
            case 'directory-result-state':
            case 'directory-result-city':
                setShowButton(true);
                setBtnText('Check Insurance');
                setBtnLink('/verify-insurance/');

                break;

            case 'facility-pages':
                setShowButton(false);
                break;
            case 'local-pages':
                setShowButton(true);
                setBtnText(' ' + callTrackingNumber?.display);
                setBtnLink(callTrackingNumber?.href);
                break;

            case 'conversion-pages':
                setShowButton(false);
                break;
        }
    });

    useEffect(() => {
        if (typeof window !== 'undefined' && window.CallTrk) {
            window.CallTrk.swap();
        }
    });

    if (showButton) {
        return (
            <SecondButton
                btnText={btnText}
                btnLink={btnLink}
                isMobile={isMobile}
                isConversionPage={isConversionPage}
                isFacilityPage={isFacilityPage}
            />
        );
    }

    return (
        <>
            {contactLoopChat?.click && (
                <Button
                    className="need-help-cta"
                    onClick={() => {
                        if (window?.webchat?.show) {
                            window.webchat.show();
                            document
                                .querySelector('#onrWebchat')
                                ?.classList?.add('onrWebchat-show');
                        } else {
                            contactLoopChat?.click?.();
                        }
                    }}
                    style={{
                        width: '100%',
                        minWidth: '120px',
                        background: 'var(--secondary)',
                        height: '48px',
                        alignItems: 'center',
                        fontSize: '22px',
                    }}>
                    <div className="need-help-cta--image">
                        <Image src="/static/aac-brandmark-white.svg" />
                    </div>
                    <div className="need-help-cta--text">Get Help</div>
                </Button>
            )}
            <style jsx>
                {`
                    .need-help-cta {
                        background: var(--secondary);
                        border-radius: 8px;
                        padding: 12px;
                        display: grid;
                        grid-template-columns: auto auto;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-weight: bold;
                        text-align: center;
                        cursor: pointer;
                    }
                    .need-help-cta--text {
                        font-family: var(--copy-title);
                        font-style: normal;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                        color: #fff;
                    }
                    .need-help-cta--image {
                        margin-right: 5px;
                        display: block;
                        min-width: fit-content;
                    }
                `}
            </style>
        </>
    );
};

export default withRouter(NeedHelpCta);
