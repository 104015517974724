import React from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

const FinancingInquiryForm = dynamic(() =>
    import('aac-components/components/FinancingInquiryForm/index.js'),
);

const StyledWrapper = styled.div`
    .primary-action {
        color: var(--white);
        font-size: 16px;
        line-height: 1;
        font-weight: bold !important;
        text-align: center;
        cursor: pointer;
    }
    .primary-action:hover {
        color: var(--white);
    }
`;

const FinanceInquiryForm = (props) => {
    return (
        <StyledWrapper>
            <FinancingInquiryForm {...props} />
        </StyledWrapper>
    );
};

export default FinanceInquiryForm;
