export default function IconQuestionCircle(props) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M8.00016 1.33325C4.31961 1.33325 1.3335 4.31936 1.3335 7.99992C1.3335 11.6805 4.31961 14.6666 8.00016 14.6666C11.6807 14.6666 14.6668 11.6805 14.6668 7.99992C14.6668 4.31936 11.6807 1.33325 8.00016 1.33325Z"
                fill={props?.fill || 'var(--interactive)'}></path>
            <path
                d="M7.34869 11.8796C7.47397 11.9581 7.62125 12 7.77193 12C7.97397 12 8.16775 11.9247 8.31061 11.7907C8.45348 11.6567 8.53374 11.475 8.53374 11.2855C8.53374 11.1442 8.48906 11.0061 8.40535 10.8886C8.32165 10.7711 8.20267 10.6795 8.06346 10.6254C7.92426 10.5713 7.77108 10.5572 7.6233 10.5848C7.47553 10.6123 7.33978 10.6804 7.23324 10.7803C7.1267 10.8802 7.05414 11.0075 7.02475 11.1461C6.99535 11.2847 7.01044 11.4284 7.0681 11.5589C7.12576 11.6895 7.22341 11.8011 7.34869 11.8796Z"
                fill="white"></path>
            <path
                d="M8.34329 9.42785C8.34329 9.10097 8.42823 8.7448 9.04568 8.35612L9.04622 8.35578C9.806 7.8768 10.6668 7.33412 10.6668 6.12586C10.6668 5.2992 10.1602 4.61722 9.23804 4.20818C8.92989 4.07171 8.47547 3.9949 7.99171 4.00026C7.30836 4.00812 6.73129 4.18603 6.27762 4.52827C5.40268 5.18845 5.33831 5.97046 5.33412 6.05727C5.33074 6.12295 5.34119 6.18861 5.36487 6.2505C5.38855 6.31239 5.425 6.3693 5.47213 6.41798C5.51926 6.46666 5.57615 6.50616 5.63956 6.53422C5.70297 6.56228 5.77165 6.57835 5.84168 6.58152C5.91171 6.58469 5.98172 6.57489 6.04771 6.55268C6.1137 6.53047 6.17438 6.49629 6.22629 6.45208C6.27819 6.40788 6.32031 6.35452 6.35023 6.29505C6.38014 6.23559 6.39728 6.17117 6.40066 6.10549C6.40066 6.09942 6.44332 5.68573 6.94688 5.30563C7.2139 5.10414 7.56052 5.00411 8.00695 4.99876C8.36272 4.99483 8.65183 5.05127 8.78172 5.10914C9.33365 5.35528 9.60029 5.68788 9.60029 6.12586C9.60029 6.76997 9.1851 7.0629 8.45071 7.52518C7.6607 8.02282 7.27675 8.64513 7.27675 9.42785C7.27675 9.5605 7.33293 9.68771 7.43294 9.7815C7.53295 9.8753 7.66859 9.92799 7.81002 9.92799C7.95145 9.92799 8.08709 9.8753 8.1871 9.7815C8.28711 9.68771 8.34329 9.5605 8.34329 9.42785Z"
                fill="white"></path>
        </svg>
    );
}
