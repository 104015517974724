import React from 'react';
import Button from 'aac-components/components/Button';
import Phone from 'aac-components/components/Icons/Basic/IconPhone';
import {BREAKPOINT} from '../../lib/styles';

const StickyFooter = ({
    btnText,
    btnLink,
    isConversionPage = false,
    isFacilityPage = false,
    isMobile = false,
}) => {
    return (
        <>
            <div className="cta-sticky-footer">
                <div className="cta-sticky-footer-call">
                    <Button
                        href={btnLink}
                        background="var(--secondary)"
                        style={{
                            width: '100%',
                            padding: '14px',
                            fontSize:
                                (isConversionPage || isFacilityPage) && isMobile
                                    ? '14px'
                                    : '16px',
                            fontWeight: 'bold',
                            letterSpacing: '-0.16px',
                            lineHeight: '16px',
                            textDecoration: 'none',
                        }}>
                        {isFacilityPage && <Phone fill="var(--white)" height="18px" />}
                        {btnText}
                    </Button>
                </div>

                <style jsx>{`
                    .cta-sticky-footer {
                        background-color: var(--secondary);
                        height: 48px;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 5px;
                        flex: 2;
                        min-width: 170px;
                    }
                    .cta-sticky-footer-call {
                        margin: 0px;
                        text-align: center;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .cta-sticky-footer {
                            color: #fff;
                            text-align: center;
                        }
                    }
                `}</style>
            </div>
        </>
    );
};
export default StickyFooter;
