import React from 'react';
import Link from 'next/link';
import {withRouter} from 'next/router';

import {splitPathname, filterPaths} from '../../lib/utils';
import {BREAKPOINT} from '../../lib/styles';
import MaxWidth from '../MaxWidth';

import Home from '../Svgs/IconHome';
import Pen from '../Svgs/Pen';

const specialAcronyms = ['aac'];

const uppercaseAcronyms = (str) => {
    const matchingAcronyms = specialAcronyms.reduce((acc, acronym) => {
        const regex = new RegExp(` ${acronym} |^${acronym} | ${acronym}$`);
        if (str === acronym || regex.test(str)) {
            acc.push(acronym);
        }
        return acc;
    }, []);

    // console.log('matchingAcronyms', matchingAcronyms);

    let strWithUppercasedAcronyms = str;
    matchingAcronyms.forEach((match) => {
        strWithUppercasedAcronyms = str.replace(match, match.toUpperCase());
    });

    // console.log('strWithUppercasedAcronyms', strWithUppercasedAcronyms);

    return strWithUppercasedAcronyms;
};

const Breadcrumbs = (props) => {
    const splitPaths = splitPathname(props.router.asPath);
    // if on the homepage or homepage with utm params return
    if (splitPaths.length === 0 || splitPaths[0].substring(0, 1) === '?') {
        return null;
    }

    const isWhite = props.isWhite || false;

    const filteredPaths = filterPaths(splitPaths);

    const currentSlug = filteredPaths.slice(-1)[0];
    // console.log('currentSlug', currentSlug);

    const paths = filteredPaths.slice(0);
    // console.log('breadcrumb path => ', paths);

    const pathLinks = filteredPaths.map((link, index) => {
        let fullPath = '/';
        for (let i = 0; i < index; i++) {
            if (i + 1 === index) {
                fullPath += filteredPaths[i];
            } else {
                fullPath += filteredPaths[i] + '/';
            }
        }
        return fullPath;
    });

    const showEditIcon = props.router.asPath.match('#edit');

    const overrides = props?.['overrides'] ?? {};
    const {className: jsxClassName = '', styles: overrideStyles = ''} = overrides;

    let _currentSlug = uppercaseAcronyms(currentSlug);

    const descriptiveTitle = props?.['title'] ?? '';

    return (
        <MaxWidth>
            <div className={`breadcrumbs ${jsxClassName}`}>
                <ul className={jsxClassName}>
                    <li>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <Link href="/" className={`link text-xs ${jsxClassName}`}>
                                <Home
                                    fill="var(--primary)"
                                    style={{
                                        marginTop: '10px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Link>
                        </div>
                    </li>

                    {paths.length === 1 && (
                        <li>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <label className="breadcrumbs__divider">
                                    &nbsp;/&nbsp;
                                </label>
                                <label className="breadcrumbs__current">
                                    {descriptiveTitle ? descriptiveTitle : _currentSlug}
                                </label>
                            </div>
                        </li>
                    )}

                    {paths.length > 1 &&
                        paths.map((slug, index) => {
                            const text = slug.split('-').join(' ');
                            const previousSlug = paths[index - 1];
                            const showHref = slug !== 'blog';

                            return (
                                <li key={index}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <label
                                            className={`breadcrumbs__divider ${
                                                isWhite ? 'white' : 'grey'
                                            }`}>
                                            &nbsp;/&nbsp;
                                        </label>

                                        {pathLinks[index + 1] && showHref ? (
                                            <Link
                                                href={`${pathLinks[index + 1]}`}
                                                className={`breadcrumbs__current link ${
                                                    isWhite ? 'white' : 'grey'
                                                } text-xs ${jsxClassName}`}>
                                                {uppercaseAcronyms(text)}
                                            </Link>
                                        ) : (
                                            <span
                                                className={`breadcrumbs__current link ${
                                                    isWhite ? 'white' : 'grey'
                                                } text-xs ${jsxClassName}`}>
                                                {uppercaseAcronyms(text)}
                                            </span>
                                        )}
                                    </div>
                                </li>
                            );
                        })}

                    {props.postId && showEditIcon && (
                        <li className={jsxClassName}>
                            <a
                                className={`edit-pen ${jsxClassName}`}
                                href={`https://admin.americanaddictioncenters.org/wp-admin/post.php?post=${props.postId}&action=edit`}
                                rel="nofollow"
                                target="_blank">
                                <Pen
                                    width="12px"
                                    height="12px"
                                    style={{marginTop: '4px'}}
                                />
                            </a>
                        </li>
                    )}
                </ul>
            </div>

            <style jsx>
                {`
                    div.breadcrumbs {
                        background: transparent;
                        border-radius: 0;
                        position: absolute;
                        padding: 24px 0 20px 15px;
                    }
                    div.breadcrumbs ul {
                        list-style-type: none;
                        display: flex;
                        flex-flow: row wrap;
                        align-items: center;
                        justify-content: flex-start;
                        margin: 0;
                        padding: 0;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        div.breadcrumbs {
                            padding: 24px 0 20px 0;
                        }
                        div.breadcrumbs ul {
                            margin: 0;
                            max-width: 1400px;
                            margin-right: auto;
                            margin-left: auto;
                        }
                    }
                    div.breadcrumbs .white {
                        color: #fff;
                    }
                    div.breadcrumbs .grey {
                        color: var(--gray-500);
                    }
                    div.breadcrumbs :global(.link) {
                        font-size: 12px;
                        font-family: var(--title-font);
                        font-weight: normal;
                        letter-spacing: -0.16px;
                        line-height: 16px;

                        text-transform: capitalize;
                        text-decoration: none;
                    }
                    div.breadcrumbs :global(a.link:hover) {
                        text-decoration: underline;
                    }
                    div.breadcrumbs label {
                        text-transform: capitalize;
                    }
                    div.breadcrumbs label.breadcrumbs__current {
                        padding: 0;
                    }
                    div.breadcrumbs__divider {
                        color: var(--gray-300);
                        font-family: var(--title-font);
                        font-size: 12px;
                        font-weight: normal;
                        letter-spacing: -0.16px;
                        line-height: 16px;
                        padding: 0 5px 0 0;
                    }
                    div.breadcrumbs .edit-pen {
                        background: var(--primary);
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        border-radius: 100%;
                        text-decoration: none;
                        opacity: 0.8;
                    }
                    div.breadcrumbs .edit-pen:hover {
                        opacity: 1;
                    }
                `}
            </style>
            {overrideStyles}
        </MaxWidth>
    );
};

export default withRouter(Breadcrumbs);
