export default function Home(props) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 15 15"
      width={props.width || "15px"}
      height={props.height || "15px"}
      fill={props.fill || "#EF395A"}
      stroke={props.stroke || "#EF395A"}
    >
      <title>BA1858B8-E38B-4463-9F0F-FA26D28E82C3@1x</title>
      <g
        id="High-Fidelity"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="exports" transform="translate(-135.000000, -296.000000)">
          <g
            id="icon/Navigation/Home"
            transform="translate(135.000000, 296.000000)"
          >
            <rect id="container" x="0" y="0" width="15" height="15"></rect>
            <path
              d="M7.50087127,2.902174 C7.44051062,2.902174 7.38247714,2.92546152 7.33885959,2.96718583 L1.94530487,8.11962709 C1.92239907,8.1415386 1.90417601,8.16786867 1.89173803,8.19702491 C1.87930005,8.22618115 1.87290562,8.25755785 1.87294155,8.28925624 L1.87206268,13.1249988 C1.87206268,13.3736393 1.97083468,13.612095 2.14665002,13.7879103 C2.32246537,13.9637257 2.5609222,14.0624988 2.80956266,14.0624988 L5.62499245,14.0624988 C5.74931268,14.0624988 5.86854054,14.0131131 5.95644821,13.9252055 C6.04435588,13.8372978 6.09374244,13.718069 6.09374244,13.5937488 L6.09374244,9.60937336 C6.09374244,9.54721324 6.11843527,9.48759887 6.16238911,9.44364503 C6.20634294,9.39969119 6.26595732,9.37499836 6.32811743,9.37499836 L8.67186737,9.37499836 C8.73402748,9.37499836 8.79364186,9.39969119 8.8375957,9.44364503 C8.88154953,9.48759887 8.90624236,9.54721324 8.90624236,9.60937336 L8.90624236,13.5937488 C8.90624236,13.718069 8.95562803,13.8372978 9.0435357,13.9252055 C9.13144337,14.0131131 9.25067212,14.0624988 9.37499235,14.0624988 L12.18925,14.0624988 C12.4378905,14.0624988 12.6763471,13.9637257 12.8521624,13.7879103 C13.0279778,13.612095 13.12675,13.3736393 13.12675,13.1249988 L13.12675,8.28925624 C13.12675,8.25755785 13.1203919,8.22618115 13.107954,8.19702491 C13.095516,8.16786867 13.0772925,8.1415386 13.0543867,8.11962709 L7.66288295,2.96718583 C7.6192654,2.92546152 7.56123192,2.902174 7.50087127,2.902174 Z"
              id="Vector"
              fill="#EF395A"
              fillRule="nonzero"
            ></path>
            <path
              d="M12.1907136,5.05634764 L12.1907136,1.87500002 C12.1907136,1.75067978 12.141328,1.63145136 12.0534203,1.54354369 C11.9655126,1.45563601 11.8462839,1.40625001 11.7219636,1.40625001 L10.3157137,1.40625001 C10.1913934,1.40625001 10.0721656,1.45563601 9.98425791,1.54354369 C9.89635024,1.63145136 9.84696368,1.75067978 9.84696368,1.87500002 L9.84696368,2.81250004 L8.15008922,1.19003904 C7.99130016,1.02949216 7.75516773,0.9375 7.49999195,0.9375 C7.24569507,0.9375 7.01014823,1.02949223 6.85135917,1.19033207 L0.6199139,7.15224637 C0.437687347,7.32802762 0.414835779,7.61718764 0.580656084,7.80761733 C0.622296033,7.85568457 0.673281403,7.89477573 0.730509184,7.92251244 C0.787736966,7.95024915 0.850010276,7.96605157 0.913539044,7.96895668 C0.977067813,7.97186179 1.04052334,7.96180918 1.10004373,7.93941125 C1.15956413,7.91701333 1.21390452,7.88273821 1.25975765,7.83867195 L7.33885914,2.02968749 C7.3824767,1.98796319 7.44051018,1.96467566 7.50087082,1.96467566 C7.56123147,1.96467566 7.61926495,1.98796319 7.6628825,2.02968749 L13.7425691,7.83867195 C13.8321265,7.92455536 13.952083,7.97142581 14.076142,7.96900943 C14.2002011,7.96659305 14.3182412,7.91508693 14.4043863,7.82578125 C14.5842691,7.63945312 14.5693274,7.3318359 14.3821204,7.15283198 L12.1907136,5.05634764 Z"
              id="Vector"
              fill="#EF395A"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
