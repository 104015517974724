import React from 'react';
import {withRouter} from 'next/router';

import NextStepsCarousel from '../../../Navigation/components/NextStepsCarousel';

const SubNavStepCTADesktop = (props) => {
    const path = props?.router?.asPath ?? '';

    return (
        <div className="secondary-nav desktop">
            <NextStepsCarousel />
            <style jsx>
                {`
                    .secondary-nav {
                        height: 66px;
                        background: var(--secondary-400);
                        box-shadow: var(--box-shadow);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                `}
            </style>
        </div>
    );
};
export default withRouter(SubNavStepCTADesktop);
