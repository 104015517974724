import React from 'react';
import {withRouter, useRouter} from 'next/router';
import {isCompliancePage, hideMobileTopMenu} from '../../../../lib/utils';
import NextStepsCarousel from '../../../Navigation/components/NextStepsCarousel';

const SubNavStepCTADevice = (props) => {
    const path = props?.router?.asPath ?? '';

    const {asPath} = useRouter();
    const hideTopMenu = hideMobileTopMenu(asPath);

    if (isCompliancePage(path)) return null;

    return (
        <div className="secondary-nav-mobile">
            <NextStepsCarousel />
            <style jsx>
                {`
                    .secondary-nav-mobile {
                        background: var(--gray-100);
                        position: relative;
                        top: 56px;
                        left: 0;
                        width: 100%;
                        box-shadow: var(--box-shadow);
                    }

                    ul {
                        margin: 0;
                        padding: 0;
                    }
                    li {
                        border-bottom: 1px solid var(--gray-300);
                        text-align: center;
                        padding: 15px 0;
                    }
                    li a {
                        font-size: 18px;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        margin: 0 auto;
                        justify-content: center;
                        color: var(--primary);
                    }
                    .text {
                        text-decoration: underline;
                        margin: 0 15px;
                    }
                `}
            </style>
        </div>
    );
};
export default withRouter(SubNavStepCTADevice);
