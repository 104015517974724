import {withRouter} from 'next/router';
import React, {useContext, useEffect, useState} from 'react';

import HelpLineInfo from '../Helpline';
import StickyNeedHelpCta from '../StickyNeedHelpCta';
import StickyFooter from './StickyFooter';

import {BREAKPOINT} from '../../lib/styles';
import {AppContext} from '../../pages/_app';
import {zIndex} from 'aac-components/utils/helpers';
import AktifyStickyFooter from '../AktifyStickyFooter';
import styled from 'styled-components';

const HelplineInfoStyles = styled.div`
    .helpline__text {
        color: ${(props) => (props.isMobile ? '#fff' : '#000')};
    }
`;

const StyledSlider = styled.div`
    .container .cta .cta__header .cta__title {
        font-size: 16px;
    }
`;

const aktifyStickyFooterSegments = [
    'info-pr',
    'info-alcohol',
    'info-drug-otc',
    'info-drug-illegal',
];

const helplineStickyFooterSegments = ['local-pages'];

const sliderFooterSegments = [
    'info-treatment',
    'facility-pages',
    'directory-result-state',
];

const StickyFooterCta = (props) => {
    const [contactLoopChat, setContactLoopChat] = useState(null);
    const [pollCount, setPollCount] = useState(0);

    const {contentSegment, isMobile} = useContext(AppContext);
    /* Display Rules - no CTA on these pages */
    const {pathname} = props?.router;
    const isVOBPage = pathname === '/verify-insurance';
    const isTextSupportPage = pathname === '/admissions/team/text-support';

    const getContactLoopIcon = () => {
        return document.querySelector('#onrWebchat #onrWebchatIframe');
    };

    // set up polling for ContactLoop
    useEffect(() => {
        // check if ContactLoop exists on mount
        const contactLoopIcon = getContactLoopIcon();
        if (contactLoopIcon) {
            setContactLoopChat(contactLoopIcon);
            return;
        }

        function continueCheckingForContactLoop() {
            const contactLoopIcon = getContactLoopIcon();
            if (contactLoopIcon) {
                setContactLoopChat(contactLoopIcon);
                setPollCount(5);
            } else {
                setPollCount(pollCount + 1);
            }
        }

        // will stop polling for ContactLoop after 5s
        if (pollCount < 5) {
            const id = setInterval(continueCheckingForContactLoop, 1000);
            return () => {
                clearInterval(id);
            };
        }
    }, [pollCount]);

    // subscribe to lead.created event once contactloop has loaded
    useEffect(() => {
        if (contactLoopChat) {
            window?.webchat?.subscribed?.(function (e) {
                window?.heap && window.heap.track('ContactLoop Lead Created');
            });
        }
    }, [contactLoopChat]);

    if (isVOBPage || isTextSupportPage) return null;

    // aktify sticky
    if (isMobile && aktifyStickyFooterSegments.includes(contentSegment)) {
        return <AktifyStickyFooter />;
    }

    if (isMobile && sliderFooterSegments.includes(contentSegment)) {
        return (
            <StyledSlider>
                <AktifyStickyFooter
                    fformHeaderCopy={'Check your Coverage'}
                    showVOB={true}
                    title="Does your insurance cover treatment? Check your coverage online instantly."
                />
            </StyledSlider>
        );
    }

    // helpline logic
    const showHelpline = helplineStickyFooterSegments.includes(contentSegment);

    return (
        <>
            <div
                className={`cta-sticky-footer-wrapper ${
                    showHelpline ? 'with-helpline' : ''
                }`}>
                <div className="parent-wrapper">
                    <StickyFooter />
                    <StickyNeedHelpCta contactLoopChat={contactLoopChat} />
                </div>

                {showHelpline && (
                    <HelplineInfoStyles isMobile={isMobile}>
                        <HelpLineInfo />
                    </HelplineInfoStyles>
                )}
            </div>
            <style jsx>{`
                .cta-sticky-footer-wrapper {
                    position: fixed;
                    bottom: 8px;
                    z-index: ${zIndex('stickyFooter')};
                    width: 100%;
                    background: transparent;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .cta-sticky-footer-wrapper {
                        max-width: fit-content;
                        right: 8px;
                        margin: 0px;
                        bottom: 15px;
                    }
                }
                .cta-sticky-footer-wrapper.with-helpline {
                    bottom: 0;
                }
                .cta-sticky-footer-wrapper .parent-wrapper {
                    display: grid;
                    grid-template-columns: 2fr 1fr;
                    justify-content: center;
                    min-width: 100%;
                    padding: 0 14px;
                    grid-gap: 8px;
                    margin: 0 auto;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .cta-sticky-footer-wrapper .parent-wrapper {
                        max-width: 400px;
                        padding: 0;
                    }
                }

                .with-helpline {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 10px 0px;
                    background: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0) 38.4%,
                        rgba(0, 0, 0, 0.75) 100%,
                        rgba(0, 0, 0, 0.75) 100%
                    );
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .with-helpline {
                        background: transparent;
                    }
                }
            `}</style>
        </>
    );
};

export default withRouter(StickyFooterCta);
