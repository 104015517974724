import {callTrackingNumber, callTrackingNumberLink} from '../../lib/globals';

const CallTrackingNum = (props) => {
    return (
        <>
            <a
                className="calltracking_number"
                href={props.phone || callTrackingNumberLink}
                style={props.style}>
                {props.children || callTrackingNumber}
            </a>
            <style jsx global="true">{`
                .calltracking_number {
                    text-align: center;
                }
            `}</style>
        </>
    );
};

export default CallTrackingNum;
