export default function Phone(props) {
    return (
        <svg
            height={props.height || '50'}
            viewBox="0 0 50 50"
            width={props.width || '50'}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="m48.5732422 35.3320312-10.9375-4.6874999c-.9598382-.4090613-2.0745056-.1343754-2.734375.6738281l-4.84375 5.9179687c-7.6018149-3.5841821-13.7195288-9.701896-17.303711-17.3037109l5.9179688-4.84375c.8099561-.6587599 1.0849569-1.7747049.6738281-2.734375l-4.6875-10.93750001c-.4530458-1.0386855-1.5805419-1.60858353-2.6855469-1.35742188l-10.15624995 2.34375c-1.06346552.2455766-1.81640631 1.19272803-1.81640631 2.28417969 0 25.0488281 20.30273446 45.3125005 45.31250006 45.3125005 1.0918168.0006996 2.0394976-.7525852 2.2851562-1.8164068l2.34375-10.15625c.2495286-1.1103098-.324613-2.241381-1.368164-2.6953125z"
                fill={props.fill || '#2880ad'}
            />
        </svg>
    );
}
