import React, {useContext, useState, useEffect} from 'react';
import Router, {useRouter} from 'next/router';
import NProgress from 'nprogress';
import Breadcrumbs from '../Breadcrumbs';
import Nav from '../Nav';
import Footer from '../Footer';
import StickyFooterCta from '../StickyFooterCta';
import {BREAKPOINT} from '../../lib/styles';
import SubNavStepCTADevice from '../Nav/navComponents/SubNavStepCTA/SubNavStepCTADevice';
import {zIndex} from 'aac-components/utils/helpers';
import {AppContext} from '../../pages/_app';
import ShortcodePortals from '../Portals/shortcodePortals';

Router.onRouteChangeStart = (url) => {
    NProgress.start();
};
Router.onRouteChangeComplete = () => {
    NProgress.done();
};
Router.onRouteChangeError = () => NProgress.done();

const Layout = (props) => {
    const [ready, setReadyState] = useState(false);
    const {isMobile} = useContext(AppContext);
    const {asPath} = useRouter();

    // for portals & callrail
    useEffect(() => {
        let timeout;
        timeout = setTimeout(() => setReadyState(true), 1000);

        // clean up
        return () => {
            clearTimeout(timeout);
            setReadyState(false);
        };
    }, [asPath]);

    const {
        style = {},
        children = '',
        postId,
        heroData,
        breadcrumbTitle = '',
        breadcrumbStyles = {},
        breadcrumbLight = false,
        showHero = true,
        enableStickyFooter = true,
        enableBreadcrumb = true,
        hideAktifyForm = false,
    } = props;

    return (
        <>
            <div className="nav-container">
                <Nav banner={true} />
            </div>

            {isMobile && <SubNavStepCTADevice />}

            <div className="main-content-wrapper page-content">
                {enableBreadcrumb && (
                    <Breadcrumbs
                        title={breadcrumbTitle}
                        postId={postId}
                        overrides={breadcrumbStyles}
                        isWhite={breadcrumbLight}
                    />
                )}
                {children}
            </div>
            <Footer hideAktifyForm={hideAktifyForm} />

            {enableStickyFooter && <StickyFooterCta {...props} />}
            {ready && <ShortcodePortals />}
            <style jsx>
                {`
                    .nav-container {
                        position: fixed;
                        width: 100%;
                        background-color: #fff;
                        z-index: ${zIndex('navigation')};
                    }
                    .page-content {
                        padding-top: 56px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .page-content {
                            padding-top: 150px;
                        }
                    }
                `}
            </style>
        </>
    );
};

export default Layout;
