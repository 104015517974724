import React, {useContext, useState} from 'react';
import {AppContext} from '../../pages/_app.js';
import ModalWithContent from '../Modal/ModalWithContent';
import IconQuestionCircle from '../Svgs/icon-question-circle';
import IconInfoCircle from '../Svgs/icon-info-circle';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const StyledHelpline = styled.button`
    position: relative;
    border: 0;
    background: transparent;
    color: var(--black);
    font-weight: 400;
    font-size: 12px;
    padding: 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
    ${(props) => props.styles};

    .helpline__text {
        margin: 0 5px;
        color: ${(props) => (props.theme === 'light' ? '#fff' : '#000')};
        font-weight: 600;
    }
    .helpline-btn:hover {
        cursor: pointer;
    }
    .helpline-btn span {
        padding-left: 3px;
    }
`;

const Helpline = ({
    type = 'helpline',
    styles,
    theme = 'dark',
    text = '',
    emptyText = false,
    iconColor = 'var(--interactive)',
}) => {
    const [open, setOpen] = useState(false);

    if (type === 'sponsored') {
        text = text || 'Sponsored';
    } else {
        text = emptyText ? '' : 'Helpline Information';
    }

    return (
        <>
            <StyledHelpline
                styles={styles}
                theme={theme}
                className="helpline"
                onClick={() => setOpen(true)}>
                {type !== 'sponsored' && <IconQuestionCircle fill={iconColor} />}
                <span className="helpline__text">{text}</span>
                {type === 'sponsored' && <IconInfoCircle fill={iconColor} />}
            </StyledHelpline>
            {open && <ModalWithContent onCloseModal={() => setOpen(false)} type={type} />}
        </>
    );
};

Helpline.propTypes = {
    styles: PropTypes.string,
    type: PropTypes.oneOf(['sponsored', 'helpline']),
    theme: PropTypes.oneOf(['dark', 'light']),
};

export default Helpline;
