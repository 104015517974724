import React, {Suspense} from 'react';
import {withRouter} from 'next/router';
import Portals from 'aac-components/components/ShortcodePortals/ShortcodePortals';
import {Quote} from 'aac-components/components/Quote';
import Callout from 'aac-components/components/Callout';
import {LinkBank} from 'aac-components/components/LinkBank';
import Sources from 'aac-components/components/Sources/Sources';
import VobShortcode from 'aac-components/components/SegmentedShortcodes/VobShortcode';
import SubstanceSearch from 'aac-components/components/SubstanceSearch';
import VOBAktifyCTA from 'aac-components/components/VobAktifyCta';
import SelfAssessment from 'aac-components/components/SelfAssessment';
import Accordion from 'aac-components/components/Accordion';
import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';
import Video from 'aac-components/components/Video';
import AktifyShortcode from 'aac-components/components/SegmentedShortcodes/AktifyShortcode';
import ExpandableCallout from 'aac-components/components/ExpandableCallout';
import FinanceForm from '../FinanceInquiryForm';
import {swappedCallTrackingNumber} from '../CallTrackingNumberWrapper';
import {getSegmentNumber} from '../../lib/utils';

const VobMemo = React.memo(VobShortcode);

const CTAHelpline = React.lazy(() => import('../CTAHelpline'));
const CTAHelplineImage = React.lazy(() => import('../CTAHelplineImage'));
const CTAVerifyBenefits = React.lazy(() => import('../CTAVerifyBenefits'));
const BACHelpline = React.lazy(() => import('../BACHelpline'));
const Helpline = React.lazy(() => import('../HelpLineInfo'));
const DefaultCta = React.lazy(() => import('../Cta/InContentCta'));

const shortcodes = [
    {
        selector: 'portal_expandable-callout',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <div style={{margin: '40px 0'}}>
                    <ExpandableCallout content={props.children} {...props} />
                </div>
            </Suspense>
        ),
    },
    {
        selector: 'portal_aktify-form',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <div style={{margin: '40px 0'}}>
                    <AktifyShortcode />
                </div>
            </Suspense>
        ),
    },
    {
        selector: 'portal_video',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <div style={{margin: '24px 0'}}>
                    <Video {...props} />
                </div>
            </Suspense>
        ),
    },
    {
        selector: 'portal_cta_helpline',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <CTAHelpline {...props} title={props.title} content={props.text} />
            </Suspense>
        ),
    },
    {
        selector: 'portal_cta_helpline_image',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <CTAHelplineImage title={props.title} content={props.text} />
            </Suspense>
        ),
    },
    {
        selector: 'portal_cta_verify_benefits',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <CTAVerifyBenefits
                    title={props.title}
                    content={props.text}
                    action={props.btn_title ?? ''}
                    actionLink={props.btn_action ?? ''}
                />
            </Suspense>
        ),
    },
    {
        selector: 'portal_bac_cta_helpline',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <BACHelpline
                    content={props.text}
                    action={props.btn_title ?? ''}
                    actionLink={props.btn_action ?? ''}
                />
            </Suspense>
        ),
    },
    {
        selector: 'portal_phone_link',
        component: (props) => {
            const {text, number, helpline} = props;
            const callTrackingNumber = swappedCallTrackingNumber();
            let phoneText = text ? text : callTrackingNumber?.display;
            let phoneLink = callTrackingNumber?.href;
            if (number) {
                phoneLink = `tel:${number}`;
                phoneText = text ? text : number;
            }
            return (
                <Suspense fallback={<div>...</div>}>
                    <a href={phoneLink}>{phoneText}</a>
                    {helpline === 'true' && (
                        <Helpline
                            dark={true}
                            styles="display: inline-flex;margin-left: 5px"
                        />
                    )}
                </Suspense>
            );
        },
    },
    {
        selector: 'portal_quote',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <Quote {...props} text={props.children} />
            </Suspense>
        ),
    },
    {
        selector: 'portal_callout',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <Callout
                    {...props}
                    buttonText={props.button}
                    imgSrc={props.image || ''}
                    imgAlt={props.alt || ''}
                    content={props.content || props.children || ''}
                />
            </Suspense>
        ),
    },
    {
        selector: 'portal_link_bank',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <LinkBank
                    {...props}
                    children={[
                        <div
                            key="link-bank-key"
                            className="link-bank__container"
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flex: 'auto',
                            }}
                            dangerouslySetInnerHTML={{__html: props.children}}
                        />,
                    ]}
                />
            </Suspense>
        ),
    },
    {
        selector: 'portal_sources',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <Sources
                    children={[
                        <div
                            key="sources-key"
                            className="sources__container"
                            dangerouslySetInnerHTML={{__html: props.children}}
                        />,
                    ]}
                />
            </Suspense>
        ),
    },
    {
        selector: 'portal_ssvob',
        component: (props) => {
            const segment = getSegmentNumber();
            return (
                <>
                    <VobAbandonModal>
                        <VobMemo
                            {...props}
                            segment={segment}
                            type={props.type ? props.type : 'in-content'}
                        />
                    </VobAbandonModal>
                </>
            );
        },
    },
    {
        selector: 'portal_substance_form',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <SubstanceSearch />
            </Suspense>
        ),
    },
    {
        selector: 'portal_accordion',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <Accordion title={props.title}>
                    <div dangerouslySetInnerHTML={{__html: props.children}} />
                </Accordion>
            </Suspense>
        ),
    },
    {
        selector: 'portal_additional_cta',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <DefaultCta />
            </Suspense>
        ),
    },
    {
        selector: 'portal_financing-inquiry-form',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <div style={{margin: '40px 0'}}>
                    <FinanceForm {...props} />
                </div>
            </Suspense>
        ),
    },
    {
        selector: 'portal_self-assessment',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <div style={{margin: '40px 0'}}>
                    <SelfAssessment {...props} />
                </div>
            </Suspense>
        ),
    },
    {
        selector: 'portal_vob_aktify_cta',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <div style={{margin: '40px 10px'}}>
                    <VOBAktifyCTA {...props} />
                </div>
            </Suspense>
        ),
    },
];

const ShortcodePortals = () => <Portals shortcodes={shortcodes} />;

export default withRouter(ShortcodePortals);
