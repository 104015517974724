import Link from 'next/link';
import Modal from 'aac-components/components/Modal';
import StyledModal from 'aac-components/components/Modal/StyledModal';
import MedicalShieldIcon from '../Svgs/MedicalShieldIcon';
import Helpline from '../Helpline';
import {AppContext} from '../../pages/_app.js';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import CallTrackingNumberWrapper from '../CallTrackingNumberWrapper';
import ShortcodePortals from '../Portals/shortcodePortals';

export const ModalWithContent = ({type = '', onCloseModal}) => {
    const {siteOptions} = useContext(AppContext);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        // Need to re-render this component to display shortcode portals
        setIsMounted(true);
    }, []);

    let modalContent = '';
    switch (type.toLowerCase()) {
        case 'helpline':
            modalContent = siteOptions?.acf?.who_answers || <WhoAnswersModal />;
            break;
        case 'sponsored':
            modalContent = siteOptions?.acf?.sponsored || <SponsoredModal />;
            break;
        case 'author':
            modalContent = <AuthorModal />;
            break;
        case 'medicallyreviewed':
            modalContent = siteOptions?.acf?.medical_reviewed || (
                <MedicallyReviewedModal />
            );
            break;
        case 'carf':
            modalContent = <CARFModal />;
            break;
        case 'naatp':
            modalContent = <NAATPModal />;
            break;
        case 'jointcommission':
            modalContent = <JointCommissionModal />;
            break;
        default:
            modalContent = <WhoAnswersModal />;
            break;
    }

    return (
        <Modal onClickOutside={() => onCloseModal()}>
            <StyledModal>
                <button className="modal-close" onClick={() => onCloseModal()}>
                    ✕
                </button>
                <div className="modal-container">
                    <div>
                        {typeof modalContent === 'string' ? (
                            <div
                                className="modal-content"
                                dangerouslySetInnerHTML={{__html: modalContent}}
                            />
                        ) : (
                            <div className="modal-content">{modalContent}</div>
                        )}
                    </div>
                </div>
                <ShortcodePortals />
            </StyledModal>
        </Modal>
    );
};

ModalWithContent.propTypes = {
    onCloseModal: PropTypes.func,
    type: PropTypes.oneOf([
        'sponsored',
        'helpline',
        'author',
        'author',
        'medicallyreviewed',
        'carf',
        'naatp',
        'adp',
        'jointcommission',
    ]), // case matters here
};

export default ModalWithContent;

const WhoAnswersModal = () => (
    <>
        <p className="title-lg">How Our Helpline Works</p>
        <p>
            For those seeking addiction treatment for themselves or a loved one, the
            Withdrawal.net helpline is a private and convenient solution.
        </p>
        <p>
            <b>
                Calls to any general helpline (non-facility specific 1-8XX numbers) for
                your visit will be answered by{' '}
                <Link href="/aac/" style={{color: 'var(--primary)'}}>
                    
                        American Addiction Centers (AAC)
                    
                </Link>
                .
            </b>
        </p>
        <p>
            We are standing by 24/7 to discuss your treatment options. Our representatives
            work solely for AAC and will discuss whether an AAC facility may be an option
            for you.
        </p>
        <p>
            Our helpline is offered at no cost to you and with no obligation to enter into
            treatment. Neither Withdrawal.net nor AAC receives any commission or other fee
            that is dependent upon which treatment provider a visitor may ultimately
            choose.
        </p>
        <p>
            For more information on AAC’s commitment to ethical marketing and treatment
            practices, or to learn more about how to select a treatment provider, visit
            our{' '}
            <a
                style={{color: 'var(--primary)'}}
                href="/about/"
                target="_blank"
                rel="noopener noreferrer">
                About AAC
            </a>{' '}
            page.
        </p>
        <p>
            If you wish to explore additional treatment options or connect with a specific
            rehab center, you can browse{' '}
            <a
                style={{color: 'var(--primary)'}}
                href="/aac-facilities/"
                target="_blank"
                rel="noopener noreferrer">
                top-rated listings
            </a>
            , visit our{' '}
            <a
                style={{color: 'var(--primary)'}}
                href="/"
                target="_blank"
                rel="noopener noreferrer">
                homepage
            </a>{' '}
            and browse by state, or visit{' '}
            <a
                style={{color: 'var(--primary)'}}
                href="https://www.samhsa.gov/find-treatment/"
                target="_blank"
                rel="noopener noreferrer">
                SAMHSA
            </a>
            .
        </p>
    </>
);

const SponsoredModal = () => (
    <>
        "Sponsored Results" feature addiction treatment providers who have paid the
        National Rehabs Directory by American Addiction Centers to appear above your
        search listings. Have questions? Contact us at
        <CallTrackingNumberWrapper styles="margin-left: 5px;" />
        <Helpline styles="display: inline-flex;margin-left: 5px" />
    </>
);
const AuthorModal = () => (
    <p>
        The editorial staff of Withdrawal.net is comprised of addiction content experts
        from American Addiction Centers. Our editors and medical reviewers have over a
        decade of cumulative experience in medical content editing and have reviewed
        thousands of pages for accuracy and relevance. Our reviewers consistently monitor
        the latest research from SAMHSA, NIDA, and other reputable sources to provide our
        readers the most accurate content on the web.
    </p>
);

const MedicallyReviewedModal = () => (
    <>
        <p style={{marginTop: 15}}>
            At American Addiction Centers, we strive to provide the most up-to-date and
            accurate medical information on the web so our readers can make informed
            decisions about their healthcare.
        </p>
        <p>
            Our reviewers are credentialed medical providers specializing in addiction
            treatment and behavioral healthcare. We follow strict guidelines when
            fact-checking information and only use credible sources when citing statistics
            and medical information. Look for the medically reviewed badge ({' '}
            <MedicalShieldIcon style={{top: '6px', marginTop: '-8px'}} />) on our articles
            for the most up-to-date and accurate information.
        </p>
        <p>
            If you feel that any of our content is inaccurate or out-of-date, please let
            us know at{' '}
            <a href="mailto:ContactAAC@ContactAAC.com">ContactAAC@ContactAAC.com</a>
        </p>
    </>
);

// Note: JCAHO === Joint Commission. everything that says JCAHO should be changed to Joint Commission – they did an official name change a couple years ago.
const JointCommissionModal = () => (
    <p>
        This facility has met the standards of and has been accredited by the Joint
        Commission.
    </p>
);

// Commission on Accreditation of Rehabilitation Facilities
const CARFModal = () => (
    <p>
        This facility has met the standards of and has been accredited by the Commission
        on Accreditation of Rehabilitation Facilities.
    </p>
);

// National Association of Addiction Treatment Providers
const NAATPModal = () => (
    <p>
        This facility has met the standards of and has been accredited by the National
        Association of Addiction Treatment Providers as a licensed alcohol and drug
        rehabilitation program.
    </p>
);

// const ADPModal = () => ();
