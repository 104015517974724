export default function SearchIcon(props) {
  return (
    <svg
      version="1.1"
      width={props.width || "18px"}
      height={props.height || "18px"}
      viewBox={props.viewBox || "0 0 18 18"}
      style={props.style}
    >
      <title>search</title>
      <defs />
      <g strokeWidth={props.strokeWidth || 1} fillRule="evenodd">
        <g
          transform="translate(-1130.000000, -81.000000)"
          fill={props.fill || ""}
          stroke={props.stroke || ""}
          strokeWidth={props.strokeWidth || 1}
          fillRule="nonzero"
        >
          <g transform="translate(0.000000, 40.000000)">
            <g transform="translate(1131.000000, 42.000000)">
              <path d="M6.23589744,12.4034188 C7.74017094,12.4034188 9.10769231,11.8700855 10.174359,10.9675214 L14.9606838,15.7538462 C15.0700855,15.8632479 15.2068376,15.9179487 15.357265,15.9179487 C15.5076923,15.9179487 15.6444444,15.8632479 15.7538462,15.7538462 C15.9726496,15.5350427 15.9726496,15.1794872 15.7538462,14.9606838 L10.9675214,10.174359 C11.8564103,9.10769231 12.4034188,7.72649573 12.4034188,6.23589744 C12.4034188,2.83076923 9.64102564,0.0683760684 6.23589744,0.0683760684 C2.84444444,0.0683760684 0.0683760684,2.84444444 0.0683760684,6.23589744 C0.0683760684,9.64102564 2.84444444,12.4034188 6.23589744,12.4034188 Z M6.23589744,1.18974359 C9.02564103,1.18974359 11.2820513,3.45982906 11.2820513,6.23589744 C11.2820513,9.02564103 9.02564103,11.2820513 6.23589744,11.2820513 C3.44615385,11.2820513 1.18974359,9.01196581 1.18974359,6.23589744 C1.18974359,3.45982906 3.45982906,1.18974359 6.23589744,1.18974359 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
