export default function IconInfoCircle(props) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M12 2C6.47917 2 2 6.47917 2 12C2 17.5208 6.47917 22 12 22C17.5208 22 22 17.5208 22 12C22 6.47917 17.5208 2 12 2Z"
                fill={props?.fill || '#047EDF'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7528 9C13.0962 9 13.3899 8.87863 13.6338 8.63588C13.8777 8.39313 14 8.09858 14 7.75296C14 7.40882 13.8777 7.11279 13.6338 6.86782C13.3905 6.62211 13.0962 6.5 12.7528 6.5C12.41 6.5 12.1143 6.62211 11.8683 6.86782C11.6244 7.11279 11.5 7.40882 11.5 7.75296C11.5 8.09784 11.6237 8.39313 11.8683 8.63588C12.1143 8.87863 12.41 9 12.7528 9ZM12.9329 17.3621C13.1665 17.2702 13.478 17.1374 13.8693 16.9623L14 16.359C13.9318 16.3947 13.8237 16.4363 13.6743 16.483C13.5237 16.5297 13.3905 16.5538 13.2714 16.5538C13.0191 16.5538 12.8409 16.5064 12.7392 16.4123C12.6362 16.3181 12.5854 16.1409 12.5854 15.8811C12.5854 15.7783 12.6008 15.6251 12.6323 15.4244C12.6645 15.2238 12.6999 15.0444 12.7398 14.8875L13.227 12.9323C13.2747 12.7521 13.3075 12.5559 13.3249 12.3407C13.3422 12.1262 13.3512 11.9759 13.3512 11.8905C13.3512 11.4791 13.2232 11.1442 12.9689 10.8867C12.7134 10.6299 12.3504 10.5 11.88 10.5C11.6193 10.5 11.3426 10.5525 11.0497 10.6591C10.7569 10.7641 10.4499 10.891 10.1294 11.0391L10 11.6432C10.094 11.6024 10.2085 11.5608 10.3405 11.5148C10.4737 11.4703 10.6037 11.4492 10.7298 11.4492C10.9879 11.4492 11.1611 11.4973 11.2531 11.5958C11.3438 11.6943 11.3895 11.8694 11.3895 12.1189C11.3895 12.2589 11.3754 12.4129 11.3458 12.5807C11.3162 12.747 11.2788 12.9258 11.2357 13.114L10.7459 15.075C10.7028 15.2815 10.6713 15.4653 10.6513 15.6287C10.632 15.7921 10.6224 15.9526 10.6224 16.1095C10.6224 16.5129 10.753 16.8449 11.0156 17.1068C11.2782 17.3687 11.647 17.5 12.1207 17.5C12.429 17.5 12.6999 17.454 12.9329 17.3621Z"
                fill="white"
            />
        </svg>
    );
}
